import '../styles/GameStatsHeader.scss';

import { useContext } from 'react';
import AnswerIconContainer from './shared/AnswerIconContainer';
import Modal from 'react-bootstrap/Modal';
import { AnswerResult } from '../common/global-enums';
import { Col, Container, Image, Row } from 'react-bootstrap';
import { GlobalStateContext, IGlobalStateContextValue } from '../context/GlobalStateContext';

const alreadyShownStorageKey = "help_modal_already_shown";

function TutorialModal() {
    const {
        showTutorialModal,
        setShowTutorialModal,
    } = useContext<IGlobalStateContextValue | undefined>(GlobalStateContext)!;

    const handleClose = () => setShowTutorialModal(false);
    const handleShow = () => {
        setShowTutorialModal(true);
        localStorage.setItem(alreadyShownStorageKey, "1");
    };

    const alreadyShown = localStorage.getItem(alreadyShownStorageKey) === "1";
    if (!alreadyShown) {
        handleShow();
    }

    return (
        <>
            <Modal show={showTutorialModal} onHide={handleClose}>
                <Modal.Header className="bg-primary" closeButton closeVariant="white">
                    <Modal.Title className="text-white">How To Play Quest10ns (Quest-Tens)</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="fs-5">Guess the secret word(s) within 10 questions!</div>
                    <div className="mt-2">
                        <ul>
                            <li>Gain clues by asking questions to AI that have a "yes" or "no" answer.</li>
                            <li>To make a guess, you can ask "Is it a ..." or enter in just the word(s).</li>
                            <li>After every 4 questions, you will be asked a "hint" question. After selecting an option, the correct answer will be shown. If you got it right, you will receive a bonus hint!</li>
                        </ul>
                    </div>
                    <hr />
                    <div className="h6">
                        Examples
                    </div>
                    <div>
                        <ul>
                            <li>
                                <div className="d-flex">
                                    <div className="flex-grow-1">Is it usually bigger than a car?</div>
                                    <div style={{ width: "2rem", height: "2rem" }}>
                                        <AnswerIconContainer answerResult={AnswerResult.YES} includeText={false}></AnswerIconContainer>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="d-flex mt-1">
                                    <div className="flex-grow-1">Does it contain any wood in it?</div>
                                    <div style={{ width: "2rem", height: "2rem" }}>
                                        <AnswerIconContainer answerResult={AnswerResult.NO} includeText={false}></AnswerIconContainer>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <hr />
                    <div className="h6">
                        Tips
                    </div>
                    <div>
                        <ul>
                            <li>The AI will sometimes make mistakes when answering questions. However, the hint answers will always be correct.</li>
                            <li>Try asking more targeted questions. For example, instead of asking "can it...", ask "does it usually...".</li>
                            <li>The AI does best when answering questions with a single query. It's more likely to give wrong answers to questions like "Is it X or Y?"</li>
                        </ul>
                    </div>
                    <hr />
                    <div className="mt-2">
                        <em>AI players like GPT and Gemini also play each game! Can you guess the word faster than AI? A new quest is available every day at midnight in your local timezone!</em>
                    </div>
                    <Container>
                        <Row className="mt-5">
                            <Col>
                                <Row className="d-flex justify-content-center">
                                    <div className="rounded-circle spotlight-circle">
                                        <span>212</span>
                                    </div>
                                </Row>
                                <Row>
                                    <div className="text-center">Humans</div>
                                </Row>
                            </Col>
                            <Col className="d-flex justify-content-center align-items-center text-secondary fs-5 fw-bold">
                                VS
                            </Col>
                            <Col>
                                <div className="d-flex justify-content-center">
                                    <Image className='crown-help' src={process.env.REACT_APP_PUBLIC_URL + 'crown.png'} />
                                </div>

                                <Row className="d-flex justify-content-center">
                                    <div className="rounded-circle spotlight-circle">
                                        <span>244</span>
                                    </div>
                                </Row>
                                <Row>
                                    <div className="text-center">AI</div>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal >
        </>
    );
}

export default TutorialModal;