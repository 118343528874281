import { RefObject, useContext, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { AnswerResult, LlmAnswerResult, GameState, answerResultToLLMAnswerResult, llmAnswerResultToAnswerResult } from '../../common/global-enums';
import { getLlmAnswer } from '../../firebase/get-llm-answer-wrapper';
import { PublishGameSessionResponse, publishGameSession } from '../../firebase/publish-game-session-wrapper';
import { IGlobalStateContextValue, GlobalStateContext } from '../../context/GlobalStateContext';
import { AskedQuestion, getQuestionKey } from '../../common/asked-question';
import { clearGame, storeGameTranscriptRecords } from '../../common/ongoing-session-storage';
import { UserCredential, getAuth, signInAnonymously } from 'firebase/auth';
import { getFirebaseAnalytics, getFirebaseApp } from '../../firebase/firebase-app';
import { UserInfo } from '../../common/user-info';
import { GameTranscriptRecord } from '../../common/game-transcript-record';
import { logEvent } from 'firebase/analytics';
import { TOP_LEVEL_COMPONENT_COL_LG, TOP_LEVEL_COMPONENT_COL_MD, TOP_LEVEL_COMPONENT_COL_XL, TOP_LEVEL_COMPONENT_COL_XXL } from '../../common/display-utils';
import { LlmQuestionResponse } from '../../common/llm-question-response';
import { setConfirmedSpoiler } from '../../common/spoiler-utils';

type Props = {
    queryInputRef: RefObject<HTMLInputElement>,
    focusOnQueryInputRef: () => void,
};

// The form where user can ask questions.
function QuestionForm(props: Props) {
    const {
        faq,
        acceptedThemeWords,
        gameState,
        setGameState,
        numQuestionsAsked,
        numAttemptsAllowed,
        showHintsAfterNumQuestions,
        validAskedQuestions,
        gameTranscriptRecords,
        setGameTranscriptRecords,
        setQuestionResponseMessage,
        setGameSessionId,
        setCurrentWinStreak,
        secretWord,
        theme,
        acceptedVariants,
        gameId,
        userInfo,
        setUserInfo,
        hints
    } = useContext<IGlobalStateContextValue | undefined>(GlobalStateContext)!;
    const firebaseAuth = getAuth(getFirebaseApp());

    const disableTextInput =
        gameState === GameState.AWAITING_ANSWER ||
        gameState === GameState.AWAITING_HINT_DISPLAY ||
        gameState === GameState.AWAITING_USER_HINT_RESPONSE ||
        gameState === GameState.AWAITING_TRANSITION_TO_COMPLETED ||
        gameState === GameState.AWAITING_TRANSITION_TO_FAILED ||
        gameState === GameState.TRANSITIONING_TO_COMPLETED ||
        gameState === GameState.TRANSITIONING_TO_FAILED ||
        gameState === GameState.COMPLETED ||
        gameState === GameState.FAILED;
    const [disableSubmitButton, setDisableSubmitButton] = useState(true);
    const [numCharacters, setNumCharacters] = useState(0);

    function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        setGameState(GameState.AWAITING_ANSWER);
        setDisableSubmitButton(true);
        setQuestionResponseMessage("");
        const query = e.currentTarget.queryInput.value.trim();
        if (didHandleDuplicateQuestion(query)) {
            return;
        }
        if (didHandleFAQQuestion(query)) {
            return;
        }
        getLlmAnswer(query, secretWord, theme, acceptedVariants, acceptedThemeWords).then((questionResponse: LlmQuestionResponse) => {
            if (questionResponse.answer === LlmAnswerResult.CORRECT_GUESS) {
                const updatedGameTranscriptRecords =
                    [...gameTranscriptRecords, GameTranscriptRecord.forAskedQuestion(AskedQuestion.create(query, AnswerResult.CORRECT_GUESS, ""))];
                setGameTranscriptRecords(updatedGameTranscriptRecords);
                handleGameFinished(/* isSuccess= */true, updatedGameTranscriptRecords);
                return;
            }
            handleAskedQuestion(query, questionResponse.answer, questionResponse.explanation);
        });
    }

    /** Potentially handles the user asking a question they already asked before. */
    function didHandleDuplicateQuestion(query: string): boolean {
        const questionKey = getQuestionKey(query);
        let isDuplicateQuestion = false;
        for (let i = 0; i < validAskedQuestions.length; i++) {
            if (validAskedQuestions[i].getNormalizedQuestionAnswerPair().getQuestionKey() === questionKey) {
                isDuplicateQuestion = true;
                setTimeout(() => {
                    setQuestionResponseMessage(`It looks like you already asked that in question #${i + 1}. I won't count this one!`);
                    setDisableSubmitButton(false);
                    setGameState(GameState.AWAITING_USER_QUESTION);
                    clearTextInputAndRefocus();
                }, 750);
                break;
            }
        }
        return isDuplicateQuestion;
    }

    /** Potentially handles the user asking a question that is stored in the FAQs. */
    function didHandleFAQQuestion(query: string): boolean {
        const questionKey = getQuestionKey(query);
        for (let i = 0; i < faq.length; i++) {
            if (faq[i].getNormalizedQuestionAnswerPair().getQuestionKey() === questionKey) {
                setTimeout(() => {
                    handleAskedQuestion(query, answerResultToLLMAnswerResult(faq[i].getNormalizedQuestionAnswerPair().getAnswer()), faq[i].getNormalizedQuestionAnswerPair().getExplanation());
                }, 750);
                return true;
            }
        }
        return false;
    }

    /** Handle the answer to the question. */
    function handleAskedQuestion(query: string, answerResult: LlmAnswerResult, explanation: string) {
        setGameState(GameState.AWAITING_USER_QUESTION);
        if (answerResult === LlmAnswerResult.ERROR) {
            setQuestionResponseMessage("I had an issue trying to get the answer. Please try again!");
            setDisableSubmitButton(false);
            return;
        }
        if (answerResult === LlmAnswerResult.INVALID) {
            setQuestionResponseMessage("I can't answer that. Try a different yes/no question!");
            setDisableSubmitButton(false);
            return;
        }
        const newNumQuestionsAsked = numQuestionsAsked + 1;
        const newAskedQuestion = AskedQuestion.create(
            query,
            llmAnswerResultToAnswerResult(answerResult),
            explanation
        );
        const updatedGameTranscriptRecords = [...gameTranscriptRecords, GameTranscriptRecord.forAskedQuestion(newAskedQuestion)];
        setGameTranscriptRecords(updatedGameTranscriptRecords);
        if (newNumQuestionsAsked >= numAttemptsAllowed) {
            handleGameFinished(/* isSuccess= */false, updatedGameTranscriptRecords);
            return;
        }
        storeGameTranscriptRecords(gameId, secretWord, updatedGameTranscriptRecords);
        if (updatedGameTranscriptRecords.length === 1) {
            logEvent(getFirebaseAnalytics(), "level_start", {
                level_name: gameId,
            });
        }
        clearTextInputAndRefocus();
        maybeShowHint(newNumQuestionsAsked);
    }

    /** Shows the hint question to the user if it's time. */
    function maybeShowHint(newNumQuestionsAsked: number) {
        if (hints.length === 0) {
            return;
        }
        if (newNumQuestionsAsked % showHintsAfterNumQuestions === 0) {
            setGameState(GameState.AWAITING_HINT_DISPLAY);
            setTimeout(() => {
                setGameState(GameState.AWAITING_USER_HINT_RESPONSE);
            }, 2000);
        }
    }

    /** Handle when the game is finished (either successfully or unsuccessfully). */
    function handleGameFinished(isSuccess: boolean, updatedGameTranscriptRecords: GameTranscriptRecord[]) {
        if (isSuccess) {
            setGameState(GameState.AWAITING_TRANSITION_TO_COMPLETED);
        } else {
            setGameState(GameState.AWAITING_TRANSITION_TO_FAILED);
        }
        if (updatedGameTranscriptRecords.length === 1) {
            // In case the user guesses it on the first try, log the "level_start" event.
            logEvent(getFirebaseAnalytics(), "level_start", {
                level_name: gameId,
            });
        }
        logEvent(getFirebaseAnalytics(), "level_end", {
            level_name: gameId,
            success: isSuccess,
        });
        const startTime = Date.now();
        getUserInfoForPublishing().then((updatedUserInfo: UserInfo) => {
            publishGameSession({
                transcriptRecords: updatedGameTranscriptRecords,
                secretWord: secretWord,
                gameId: gameId,
                userId: updatedUserInfo.getUserId()
            }).then((publishResponse: PublishGameSessionResponse) => {
                const elapsedTime = Date.now() - startTime;
                // Stay on the AWAITNG_TRANSITION_TO_* state for at least 2 seconds.
                setTimeout(() => {
                    clearGame(gameId);
                    setConfirmedSpoiler(gameId);
                    setGameSessionId(publishResponse.gameSessionId);
                    setCurrentWinStreak(publishResponse.currentWinStreak);
                    if (isSuccess) {
                        setGameState(GameState.TRANSITIONING_TO_COMPLETED);
                    } else {
                        setGameState(GameState.TRANSITIONING_TO_FAILED);
                    }
                    setUserInfo(updatedUserInfo);
                }, Math.max(2000 - elapsedTime, 0));
            });
        });
    }

    function getUserInfoForPublishing(): Promise<UserInfo> {
        if (userInfo.hasUser()) {
            return Promise.resolve(userInfo);
        }
        return signInAnonymously(firebaseAuth).then((userCredential: UserCredential) => {
            return UserInfo.createUnauthenticated(userCredential.user.uid);
        });
    }

    function onQueryInputChange(e: React.ChangeEvent<HTMLInputElement>) {
        if (e.target.value.length === 0) {
            setDisableSubmitButton(true);
        } else {
            setDisableSubmitButton(false);
        }
        setNumCharacters(e.target.value.length);
        if (e.target.value.length > 100) {
            setDisableSubmitButton(true);
        }
    }

    function clearTextInputAndRefocus() {
        if (props.queryInputRef.current) {
            props.queryInputRef.current!.value = "";
            setNumCharacters(0);
            props.focusOnQueryInputRef();
        }
    }

    return (
        <Row className="justify-content-sm-center">
            <Col md={TOP_LEVEL_COMPONENT_COL_MD} lg={TOP_LEVEL_COMPONENT_COL_LG} xl={TOP_LEVEL_COMPONENT_COL_XL} xxl={TOP_LEVEL_COMPONENT_COL_XXL}>
                <form onSubmit={handleSubmit}>
                    <InputGroup>
                        <Form.Control
                            size="lg"
                            name="queryInput"
                            ref={props.queryInputRef}
                            type="text"
                            placeholder="Ask a yes/no question"
                            aria-label="Ask a yes/no question"
                            disabled={disableTextInput}
                            onChange={onQueryInputChange}
                            autoFocus
                        />
                        <Button type="submit" variant="primary" disabled={disableSubmitButton}>
                            Submit
                        </Button>
                    </InputGroup>
                </form>
                <div className={"float-end me-6 fs-6 " + (numCharacters > 100 ? "text-danger" : "text-muted")}>
                    {numCharacters}/100 characters
                </div>
            </Col>
        </Row >
    );
}

export default QuestionForm;