import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import { getFirebaseApp } from './firebase-app';
import { UserStats } from "../common/user-stats";
import { Game, GameDto } from "../common/game";

/* Represents what's sent in the request to the Cloud function. */
interface FunctionRequest {
    userId: string
}

/* Represents what's returned in the response from the Cloud function. */
interface FunctionResponse {
    currentWinStreak: number;
    maxWinStreak: number;
    dailyWordResultCountMap: any;
    userCreatedGames: GameDto[];
}

/** 
 * Calls the Cloud function to get the user stats.
 */
export function getUserStats(userId: string): Promise<UserStats> {
    const functions = getFunctions(getFirebaseApp());
    // Connect to local functions when running locally.
    if (process.env.NODE_ENV === "development") {
        connectFunctionsEmulator(functions, "localhost", 5001);
    }
    const getUserStats = httpsCallable<FunctionRequest, FunctionResponse>(functions, 'getUserStats');
    return getUserStats({ userId }).then((result) => {
        const dailyWordResultCountMap = new Map<number, number>();
        const dailyWordResultCountObj = result.data.dailyWordResultCountMap;
        Object.keys(dailyWordResultCountObj).forEach((key) => {
            dailyWordResultCountMap.set(Number(key), dailyWordResultCountObj[key]);
        });
        return new UserStats(
            result.data.currentWinStreak,
            result.data.maxWinStreak,
            dailyWordResultCountMap,
            result.data.userCreatedGames.map((gameDto) => Game.fromDto(gameDto)));
    }).catch((error) => {
        console.log("Error while getting user stats from endpoint: " + error);
        throw error;
    });
}

