import '../../styles/CreateGameForm.scss';

import { Alert, Button, Card, Form, InputGroup, OverlayTrigger, Popover } from "react-bootstrap";
import { QuestionCircle, Trash } from "react-bootstrap-icons";
import * as formik from 'formik';
import { createGame } from '../../firebase/create-game-wrapper';
import { useContext, useState } from 'react';
import { GlobalStateContext, IGlobalStateContextValue } from '../../context/GlobalStateContext';
import { HintDto } from '../../common/hint';
import { hintAnswerOptionsMaxCharacters, hintBonusMaxCharacters, hintQuestionMaxCharacters, secretWordMaxCharacters, secretWordMinCharacters, themeMaxCharacters } from '../../common/validation-constants';

// Form for creating a custom game.
function CreateGameForm() {
    const {
        userInfo,
        setShowSignInModal,
    } = useContext<IGlobalStateContextValue | undefined>(GlobalStateContext)!;

    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [gameId, setGameId] = useState("");
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [disableForm, setDisableForm] = useState(!userInfo.hasUser());

    function addHint1AnswerOptionInput(values: any, setFieldValue: any) {
        setFieldValue('hint1AnswerOptions', [...values.hint1AnswerOptions, ""]);
    }

    function removeHint1AnswerOptionInput(values: any, setFieldValue: any, index: number) {
        setFieldValue('hint1AnswerOptions', [...values.hint1AnswerOptions.slice(0, index), ...values.hint1AnswerOptions.slice(index + 1)]);
    }

    function addHint2AnswerOptionInput(values: any, setFieldValue: any) {
        setFieldValue('hint2AnswerOptions', [...values.hint2AnswerOptions, ""]);
    }

    function removeHint2AnswerOptionInput(values: any, setFieldValue: any, index: number) {
        setFieldValue('hint2AnswerOptions', [...values.hint2AnswerOptions.slice(0, index), ...values.hint2AnswerOptions.slice(index + 1)]);
    }

    const secretWordPopover = (
        <Popover id="secret-word-popover">
            <Popover.Body>
                <p>This is a single concept that users are trying to guess. It can be multiple words, e.g. James Bond. This field is what will be shown to users as the correct answer.</p>

                <p>Keep in mind that the AI only has knowledge of events up to Oct 2023.</p>
            </Popover.Body>
        </Popover>
    );

    const hintPopover = (
        <Popover id="hint-popover">
            <Popover.Body>
                <p>Create two hints that will appear after the user has asked 4 and 8 questions. Each hint contains:</p>

                <ul>
                    <li>Question that the user will have to answer to test their knowledge</li>
                    <li>2-4 options for the user to select from</li>
                    <li>Bonus info if the user correctly answers your question</li>
                </ul>
            </Popover.Body>
        </Popover>
    );

    function validateForm(values: any) {
        const errors: any = {};

        if (!values.secretWord || values.secretWord.trim().length === 0) {
            errors.secretWord = "Required";
        } else if (values.secretWord.length < secretWordMinCharacters) {
            // Create an empty error so no message appears.
            errors.secretWord = " ";
        } else if (values.secretWord.length > secretWordMaxCharacters) {
            // Create an empty error so no message appears.
            errors.secretWord = " ";
        }

        if (!values.theme || values.theme.trim().length === 0) {
            errors.theme = "Required";
        } else if (values.theme.length > themeMaxCharacters) {
            // Create an empty error so no message appears.
            errors.theme = " ";
        }

        if (!values.hint1Question || values.hint1Question.trim().length === 0) {
            errors.hint1Question = "Required";
        } else if (values.hint1Question.length > hintQuestionMaxCharacters) {
            // Create an empty error so no message appears.
            errors.hint1Question = " ";
        }

        for (let i = 0; i < values.hint1AnswerOptions.length; i++) {
            if (!values.hint1AnswerOptions[i] || values.hint1AnswerOptions[i].length === 0) {
                if (!errors.hint1AnswerOptions) {
                    errors.hint1AnswerOptions = new Array<string>(values.hint1AnswerOptions.length);
                }
                errors.hint1AnswerOptions[i] = "Required";
            }
            if (values.hint1AnswerOptions[i].length > hintAnswerOptionsMaxCharacters) {
                if (!errors.hint1AnswerOptions) {
                    errors.hint1AnswerOptions = new Array<string>(values.hint1AnswerOptions.length);
                }
                // Create an empty error so no message appears.
                errors.hint1AnswerOptions[i] = " ";
            }
        }

        if (values.hint1AnswerOptionsRadio.length === 0 || values.hint1AnswerOptionsRadio > (values.hint1AnswerOptions.length - 1)) {
            // Create an empty error so no message appears.
            errors.hint1AnswerOptionsRadio = " ";
        }

        if (!values.hint1Bonus || values.hint1Bonus.trim().length === 0) {
            errors.hint1Bonus = "Required";
        } else if (values.hint1Bonus.length > hintBonusMaxCharacters) {
            // Create an empty error so no message appears.
            errors.hint1Bonus = " ";
        }

        if (!values.hint2Question || values.hint2Question.trim().length === 0) {
            errors.hint2Question = "Required";
        } else if (values.hint2Question.length > hintQuestionMaxCharacters) {
            // Create an empty error so no message appears.
            errors.hint2Question = " ";
        }

        for (let i = 0; i < values.hint2AnswerOptions.length; i++) {
            if (!values.hint2AnswerOptions[i] || values.hint2AnswerOptions[i].length === 0) {
                if (!errors.hint2AnswerOptions) {
                    errors.hint2AnswerOptions = new Array<string>(values.hint1AnswerOptions.length);
                }
                errors.hint2AnswerOptions[i] = "Required";
            }
            if (values.hint2AnswerOptions[i].length > hintAnswerOptionsMaxCharacters) {
                if (!errors.hint2AnswerOptions) {
                    errors.hint2AnswerOptions = new Array<string>(values.hint1AnswerOptions.length);
                }
                // Create an empty error so no message appears.
                errors.hint2AnswerOptions[i] = " ";
            }
        }

        if (values.hint2AnswerOptionsRadio.length === 0 || values.hint2AnswerOptionsRadio > (values.hint2AnswerOptions.length - 1)) {
            // Create an empty error so no message appears.
            errors.hint2AnswerOptionsRadio = " ";
        }

        if (!values.hint2Bonus || values.hint2Bonus.trim().length === 0) {
            errors.hint2Bonus = "Required";
        } else if (values.hint2Bonus.length > hintBonusMaxCharacters) {
            // Create an empty error so no message appears.
            errors.hint2Bonus = " ";
        }

        console.log(errors);

        return errors;
    }

    function submitForm(values: any, actions: any) {
        console.log(values);
        setDisableForm(true);
        const hint1Dto: HintDto = {
            question: values.hint1Question,
            answerIndex: Number(values.hint1AnswerOptionsRadio),
            choices: values.hint1AnswerOptions,
            bonusInfo: values.hint1Bonus
        };
        const hint2Dto: HintDto = {
            question: values.hint2Question,
            answerIndex: Number(values.hint2AnswerOptionsRadio),
            choices: values.hint2AnswerOptions,
            bonusInfo: values.hint2Bonus
        };
        createGame({
            word: values.secretWord,
            theme: values.theme,
            hints: [hint1Dto, hint2Dto],
            creatorUserId: userInfo.getUserId()
        }).then((gameId) => {
            setShowSuccessMessage(true);
            setGameId(gameId);
            window.scrollTo(0, 0);
            actions.resetForm();
            setDisableForm(false);
        }).catch((error) => {
            setShowErrorMessage(true);
            if (error.message && error.message.length > 0) {
                setErrorMessage(error.message);
            }
            window.scrollTo(0, 0);
            setDisableForm(false);
        });
    }

    return (
        <>
            <Card>
                <Card.Header className="text-center">
                    {!userInfo.hasUser() &&
                        <Alert variant="warning" className="text-center">
                            To create a custom game, you must either <Alert.Link onClick={() => setShowSignInModal(true)}>sign in</Alert.Link> or finish at least one Quest10ns game.
                        </Alert>
                    }
                    <h4 className="text-primary">Create Custom Quest</h4>
                    Create your very own Quest10ns game for others to play!
                </Card.Header>
                <Card.Body>
                    {showSuccessMessage && gameId.length > 0 &&
                        <Alert variant="success" className="text-center" onClose={() => setShowSuccessMessage(false)} dismissible>
                            Your game has been created at <Alert.Link href={process.env.REACT_APP_PUBLIC_URL + "game/" + gameId} target="_blank">{process.env.REACT_APP_PUBLIC_URL + "game/" + gameId}</Alert.Link>
                        </Alert>
                    }
                    {showErrorMessage &&
                        <Alert variant="danger" className="text-center" onClose={() => setShowErrorMessage(false)} dismissible>
                            {errorMessage && errorMessage}
                            {!errorMessage && <>We had an error creating the game. Please try again.</>}
                        </Alert>
                    }
                    <formik.Formik
                        validate={validateForm}
                        initialValues={{
                            secretWord: '',
                            theme: '',
                            hint1Question: '',
                            hint1AnswerOptions: ["", ""],
                            hint1AnswerOptionsRadio: "",
                            hint1Bonus: '',
                            hint2Question: '',
                            hint2AnswerOptions: ["", ""],
                            hint2AnswerOptionsRadio: "",
                            hint2Bonus: '',
                        }}
                        onSubmit={submitForm}>
                        {({ handleSubmit, handleBlur, handleChange, setFieldValue, values, touched, errors }) => (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        <span><strong>Secret Word/Phrase</strong> <OverlayTrigger trigger="click" placement="bottom" rootClose overlay={secretWordPopover}><QuestionCircle className="tutorial-icon" /></OverlayTrigger></span>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="secretWord"
                                        value={values.secretWord}
                                        placeholder="The word/phrase the user is trying to guess"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        isInvalid={touched.secretWord && !!errors.secretWord}
                                        disabled={disableForm} />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.secretWord}
                                    </Form.Control.Feedback>
                                    {touched.secretWord && !!errors.secretWord && errors.secretWord === " " &&
                                        <Form.Text>
                                            <div className="text-end w-100 text-danger">
                                                {values.secretWord.length}/{secretWordMaxCharacters} characters
                                            </div>
                                        </Form.Text>
                                    }
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label><strong>Theme</strong></Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="theme"
                                        value={values.theme}
                                        placeholder="The theme for the game"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        isInvalid={touched.theme && !!errors.theme}
                                        disabled={disableForm} />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.theme}
                                    </Form.Control.Feedback>
                                    {touched.theme && !!errors.theme && errors.theme === " " &&
                                        <Form.Text>
                                            <div className="text-end w-100 text-danger">
                                                {values.theme.length}/{themeMaxCharacters} characters
                                            </div>
                                        </Form.Text>
                                    }
                                </Form.Group>

                                <hr />

                                <Form.Group className="mb-3">
                                    <Form.Label><strong>Hint #1</strong> <OverlayTrigger trigger="click" placement="top" rootClose overlay={hintPopover}><QuestionCircle className="tutorial-icon" /></OverlayTrigger></Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="hint1Question"
                                        value={values.hint1Question}
                                        placeholder="Hint #1 Question"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        isInvalid={touched.hint1Question && !!errors.hint1Question}
                                        disabled={disableForm} />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.hint1Question}
                                    </Form.Control.Feedback>
                                    {touched.hint1Question && !!errors.hint1Question && errors.hint1Question === " " &&
                                        <Form.Text>
                                            <div className="text-end w-100 text-danger">
                                                {values.hint1Question.length}/{hintQuestionMaxCharacters} characters
                                            </div>
                                        </Form.Text>
                                    }
                                </Form.Group>

                                {values.hint1AnswerOptions.map((_unused, index) =>
                                    <div className="mb-3" key={"hint1AnswerOptions" + index}>
                                        <Form.Group >
                                            <Form.Check className="d-flex align-items-center">
                                                <Form.Check.Input
                                                    className="me-2"
                                                    type="radio"
                                                    name="hint1AnswerOptionsRadio"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={index}
                                                    checked={values.hint1AnswerOptionsRadio == index.toString()}
                                                    isInvalid={touched.hint1AnswerOptionsRadio && !!errors.hint1AnswerOptionsRadio}
                                                    disabled={disableForm} />
                                                <InputGroup>
                                                    <Form.Control
                                                        className="w-50"
                                                        type="text"
                                                        name={"hint1AnswerOptions[" + index + "]"}
                                                        value={values.hint1AnswerOptions[index]}
                                                        placeholder="Hint #1 Answer Option"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        isInvalid={touched.hint1AnswerOptions && errors.hint1AnswerOptions !== undefined && !!errors.hint1AnswerOptions[index]}
                                                        disabled={disableForm} />
                                                    {index > 1 && <Button variant="outline-danger" size="sm" onClick={() => removeHint1AnswerOptionInput(values, setFieldValue, index)}><Trash /></Button>}
                                                    {errors.hint1AnswerOptions !== undefined && errors.hint1AnswerOptions[index] !== " " &&
                                                        <Form.Control.Feedback type="invalid" className="w-25 ms-1">
                                                            {errors.hint1AnswerOptions !== undefined && errors.hint1AnswerOptions[index]}
                                                        </Form.Control.Feedback>
                                                    }
                                                </InputGroup>
                                            </Form.Check>
                                        </Form.Group>
                                        {touched.hint1AnswerOptions !== undefined && touched.hint1AnswerOptions && errors.hint1AnswerOptions !== undefined && !!errors.hint1AnswerOptions[index] && errors.hint1AnswerOptions[index] === " " &&
                                            <Form.Text>
                                                <div className="text-end w-100 text-danger">
                                                    {values.hint1AnswerOptions[index].length}/{hintAnswerOptionsMaxCharacters} characters
                                                </div>
                                            </Form.Text>
                                        }
                                    </div>
                                )}

                                {values.hint1AnswerOptions.length < 4 &&
                                    <div className="mb-3">
                                        <Button
                                            variant="outline-primary"
                                            size="sm"
                                            onClick={() => addHint1AnswerOptionInput(values, setFieldValue)}
                                            disabled={disableForm}>
                                            Add Hint #1 Answer Option
                                        </Button>
                                    </div>
                                }

                                <Form.Group className="mb-3">
                                    <Form.Control
                                        type="text"
                                        name="hint1Bonus"
                                        value={values.hint1Bonus}
                                        placeholder="Hint #1 Bonus Info"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        isInvalid={touched.hint1Bonus && !!errors.hint1Bonus}
                                        disabled={disableForm} />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.hint1Bonus}
                                    </Form.Control.Feedback>
                                    {touched.hint1Bonus && !!errors.hint1Bonus && errors.hint1Bonus === " " &&
                                        <Form.Text>
                                            <div className="text-end w-100 text-danger">
                                                {values.hint1Bonus.length}/{hintBonusMaxCharacters} characters
                                            </div>
                                        </Form.Text>
                                    }
                                </Form.Group>

                                <hr />

                                <Form.Group className="mb-3">
                                    <Form.Label><strong>Hint #2</strong></Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="hint2Question"
                                        value={values.hint2Question}
                                        placeholder="Hint #2 Question"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        isInvalid={touched.hint2Question && !!errors.hint2Question}
                                        disabled={disableForm} />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.hint2Question}
                                    </Form.Control.Feedback>
                                    {touched.hint2Question && !!errors.hint2Question && errors.hint2Question === " " &&
                                        <Form.Text>
                                            <div className="text-end w-100 text-danger">
                                                {values.hint2Question.length}/{hintQuestionMaxCharacters} characters
                                            </div>
                                        </Form.Text>
                                    }
                                </Form.Group>

                                {values.hint2AnswerOptions.map((_unused, index) =>
                                    <div className="mb-3" key={"hint2AnswerOptions" + index}>
                                        <Form.Group >
                                            <Form.Check className="d-flex align-items-center">
                                                <Form.Check.Input
                                                    className="me-2"
                                                    type="radio"
                                                    name="hint2AnswerOptionsRadio"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={index}
                                                    checked={values.hint2AnswerOptionsRadio == index.toString()}
                                                    isInvalid={touched.hint2AnswerOptionsRadio && !!errors.hint2AnswerOptionsRadio}
                                                    disabled={disableForm} />
                                                <InputGroup>
                                                    <Form.Control
                                                        className="w-50"
                                                        type="text"
                                                        name={"hint2AnswerOptions[" + index + "]"}
                                                        value={values.hint2AnswerOptions[index]}
                                                        placeholder="Hint #2 Answer Option"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        isInvalid={touched.hint2AnswerOptions && errors.hint2AnswerOptions !== undefined && !!errors.hint2AnswerOptions[index]}
                                                        disabled={disableForm} />
                                                    {index > 1 &&
                                                        <Button
                                                            variant="outline-danger"
                                                            size="sm"
                                                            onClick={() => removeHint2AnswerOptionInput(values, setFieldValue, index)}
                                                            disabled={disableForm}>
                                                            <Trash />
                                                        </Button>}
                                                    {errors.hint2AnswerOptions !== undefined && errors.hint2AnswerOptions[index] !== " " &&
                                                        <Form.Control.Feedback type="invalid" className="w-25 ms-1">
                                                            {errors.hint2AnswerOptions !== undefined && errors.hint2AnswerOptions[index]}
                                                        </Form.Control.Feedback>
                                                    }
                                                </InputGroup>
                                            </Form.Check>
                                        </Form.Group>
                                        {touched.hint2AnswerOptions !== undefined && touched.hint2AnswerOptions && errors.hint2AnswerOptions !== undefined && !!errors.hint2AnswerOptions[index] && errors.hint2AnswerOptions[index] === " " &&
                                            <Form.Text>
                                                <div className="text-end w-100 text-danger">
                                                    {values.hint2AnswerOptions[index].length}/{hintAnswerOptionsMaxCharacters} characters
                                                </div>
                                            </Form.Text>
                                        }
                                    </div>
                                )}

                                {values.hint2AnswerOptions.length < 4 &&
                                    <div className="mb-3">
                                        <Button
                                            variant="outline-primary"
                                            size="sm"
                                            onClick={() => addHint2AnswerOptionInput(values, setFieldValue)}
                                            disabled={disableForm}>
                                            Add Hint #2 Answer Option
                                        </Button>
                                    </div>
                                }

                                <Form.Group className="mb-3">
                                    <Form.Control
                                        type="text"
                                        name="hint2Bonus"
                                        value={values.hint2Bonus}
                                        placeholder="Hint #2 Bonus Info"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        isInvalid={touched.hint2Bonus && !!errors.hint2Bonus}
                                        disabled={disableForm} />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.hint2Bonus}
                                    </Form.Control.Feedback>
                                    {touched.hint2Bonus && !!errors.hint2Bonus && errors.hint2Bonus === " " &&
                                        <Form.Text>
                                            <div className="text-end w-100 text-danger">
                                                {values.hint2Bonus.length}/{hintBonusMaxCharacters} characters
                                            </div>
                                        </Form.Text>
                                    }
                                </Form.Group>

                                <div className="text-center d-grid mb-3">
                                    <Button type="submit" variant="primary" disabled={disableForm}>
                                        Create Quest
                                    </Button>
                                </div>

                            </Form>
                        )}
                    </formik.Formik>
                </Card.Body>
            </Card>
        </>
    );
}

export default CreateGameForm;