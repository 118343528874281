import { DateTime } from "luxon";

// The Column Specs to use for top-level UX components.
export const TOP_LEVEL_COMPONENT_COL_MD = { span: 9 };
export const TOP_LEVEL_COMPONENT_COL_LG = { span: 7 };
export const TOP_LEVEL_COMPONENT_COL_XL = { span: 6 };
export const TOP_LEVEL_COMPONENT_COL_XXL = { span: 6 };

export const getFormattedDateForGameId = (gameId: string) => {
    return gameId?.startsWith("dailyword:") ?
        DateTime.fromISO(gameId.slice(10)).setLocale("en-US").toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)
        : "";
}