import { Card, Col, Row } from "react-bootstrap";
import ThemeContainer from "../shared/ThemeContainer";
import QuestionCounter from "./QuestionCounter";
import { TOP_LEVEL_COMPONENT_COL_LG, TOP_LEVEL_COMPONENT_COL_MD, TOP_LEVEL_COMPONENT_COL_XL, TOP_LEVEL_COMPONENT_COL_XXL } from "../../common/display-utils";

function OngoingIntroCard() {
    return (
        <Row className="justify-content-sm-center">
            <Col md={TOP_LEVEL_COMPONENT_COL_MD} lg={TOP_LEVEL_COMPONENT_COL_LG} xl={TOP_LEVEL_COMPONENT_COL_XL} xxl={TOP_LEVEL_COMPONENT_COL_XXL}>
                <Card>
                    <Card.Header>
                        <ThemeContainer />
                    </Card.Header>
                    <Card.Body>
                        <QuestionCounter />
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    );
}

export default OngoingIntroCard;