// Represents the current state of the game.
export enum GameState {
    NOT_STARTED, // The user has not entered in a question yet.

    // Game is ongoing
    AWAITING_USER_QUESTION, // Waiting for the user to enter in the next question.
    AWAITING_ANSWER, // Waiting for the answer to the user's question.
    AWAITING_HINT_DISPLAY, // Waiting for the hint question to be displayed to the user.
    AWAITING_USER_HINT_RESPONSE, // Waiting for the user to respond to the hint question.

    // Game is transitioning to finished
    AWAITING_TRANSITION_TO_COMPLETED, // User has just completed the game and we're waiting to transition.
    AWAITING_TRANSITION_TO_FAILED, // User has just failed the game and we're waiting to transition.
    TRANSITIONING_TO_COMPLETED, // In the midst of transitioning to the completed state.
    TRANSITIONING_TO_FAILED, // In the midst of transitioning to the failed state.

    // Game is finished
    COMPLETED, // The user successfully guessed the word.
    FAILED,// The user did not guess the word in twenty questions.
}

// Represents the answer result to the user's question.
export enum AnswerResult {
    NO,
    YES,
    MAYBE,
    CORRECT_GUESS, // User guessed the word correctly
    UNSPECIFIED
}

/**
 * Represents the possible results from calling the LLM to
 * retrieve an answer to the user's question.
 */
export enum LlmAnswerResult {
    // The answer to the user's question is no.
    NO,
    // The answer to the user's question is yes.
    YES,
    // The answer to the user's question is maybe, e.g. there's not a
    // definitive yes/no answer.
    MAYBE,
    // The user correctly guessed the answer.
    CORRECT_GUESS,
    // The user asked an invalid question, e.g. not yes/no.
    INVALID,
    // There was an error calling the LLM.
    ERROR,
}

export function llmAnswerResultToAnswerResult(llmAnswerResult: LlmAnswerResult): AnswerResult {
    if (llmAnswerResult === LlmAnswerResult.YES) {
        return AnswerResult.YES;
    }
    if (llmAnswerResult === LlmAnswerResult.NO) {
        return AnswerResult.NO;
    }
    if (llmAnswerResult === LlmAnswerResult.MAYBE) {
        return AnswerResult.MAYBE;
    }
    if (llmAnswerResult === LlmAnswerResult.CORRECT_GUESS) {
        return AnswerResult.CORRECT_GUESS;
    }
    return AnswerResult.UNSPECIFIED;
}

export function answerResultToLLMAnswerResult(answerResult: AnswerResult): LlmAnswerResult {
    if (answerResult === AnswerResult.YES) {
        return LlmAnswerResult.YES;
    }
    if (answerResult === AnswerResult.NO) {
        return LlmAnswerResult.NO;
    }
    if (answerResult === AnswerResult.MAYBE) {
        return LlmAnswerResult.MAYBE;
    }
    if (answerResult === AnswerResult.CORRECT_GUESS) {
        return LlmAnswerResult.CORRECT_GUESS;
    }
    return LlmAnswerResult.INVALID;
}

/**
 * The name of the LLM model being used.
 */
export enum LlmModelName {
    GEMINI_PRO_1_0 = "gemini-pro-1.0",
    GEMINI_1_5_FLASH = "gemini-1.5-flash",
    GPT_3_5_TURBO = "gpt-3.5-turbo",
    GPT_4_O_MINI = "gpt-4o-mini",
    LLAMA_3_8B_INSTRUCT = "llama-3-8b-instruct",
    CLAUDE_3_HAIKU = "claude-3-haiku",
}

/**
 * Returns whether the given userId is a LLM player.
 */
export function isLlmPlayer(userId: string) {
    return Object.values<string>(LlmModelName).includes(userId);
}

// Represents the user's result for a particular game.
export enum UserGameResult {
    NOT_PLAYED,
    WON,
    LOST
}