import '../styles/GameStatsHeader.scss';

import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Megaphone } from 'react-bootstrap-icons';
import { Accordion, Badge, Image } from 'react-bootstrap';

const lastSeenAnnouncementsVersionStorageKey = "last_seen_announcements_version";
// The current version of the announcements modal. Bump this to show an unread indicator.
const currentAnnouncementVersion = 7;

function AnnouncementsButton() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
        localStorage.setItem(lastSeenAnnouncementsVersionStorageKey, currentAnnouncementVersion.toString());
    };

    const storedVersion = localStorage.getItem(lastSeenAnnouncementsVersionStorageKey);
    const showUnreadIndicator = storedVersion === null || Number(storedVersion) < currentAnnouncementVersion;

    return (
        <>
            <Button className="float-end" size="sm" variant="outline-primary" onClick={handleShow}>
                <Megaphone />
                {showUnreadIndicator &&
                    <Badge className="ms-1">New</Badge>
                }
            </Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header className="bg-primary" closeButton closeVariant="white">
                    <Modal.Title className="text-white">Announcements</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Accordion defaultActiveKey="8">
                        <Accordion.Item eventKey="8">
                            <Accordion.Header>[2024-09-29] Browse Custom and Daily Quests</Accordion.Header>
                            <Accordion.Body>
                                <p>Can't get enough Quest10ns? You can now browse custom games that others have created!</p>
                                <p>In addition, you can play older daily quests, although they won't count towards your win streak.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="7">
                            <Accordion.Header>[2024-08-30] Create Custom Quests</Accordion.Header>
                            <Accordion.Body>
                                <p>You can now create your very own custom quests for others to play <a href={process.env.REACT_APP_PUBLIC_URL + "create"} target="_blank">here</a>!</p>
                                <p>For now, you will need to share the created quest link directly with others. In the future, users will be able to browse custom quests.</p>
                                <p>You can view your created quests from your <a href={process.env.REACT_APP_PUBLIC_URL + "profile"} target="_blank">profile page</a>.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="6">
                            <Accordion.Header>[2024-08-03] View Guess Distribution for Quests</Accordion.Header>
                            <Accordion.Body>
                                <p>On the game result page, you can now see how other users did:</p>
                                <Image className="w-100" src={process.env.REACT_APP_PUBLIC_URL + "game-guess-distribution.png"} />
                                <p className="mt-4">When viewing the AI explanation for a top asked question, you will also see how many times it was asked.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="5">
                            <Accordion.Header>[2024-06-15] Mark Bad Answers as Misleading</Accordion.Header>
                            <Accordion.Body>
                                <p>After finishing a game, you can now mark the AI answers to your questions as "misleading".</p>
                                <Image className="w-100" src={process.env.REACT_APP_PUBLIC_URL + "mark-as-misleading.png"} />
                                <p className="mt-4">If enough users mark an answer as misleading, a warning will appear for future players who ask the same question.</p>
                                <Image className="w-100" src={process.env.REACT_APP_PUBLIC_URL + "percent-marked-as-misleading.png"} />
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="4">
                            <Accordion.Header>[2024-06-05] View Top Asked Questions</Accordion.Header>
                            <Accordion.Body>
                                <p>After finishing a game, you will now see a button to view the "full game results". This will take you to a page where you can see the global top asked questions for that game, along with the AI explanations.</p>
                                <p><a href="https://quest10ns.com/gameResult/dailyword:2024-06-05">Example Link</a></p>
                                <p>You can see the results for previous days by changing the date in the URL. Note that the URL for future days won't be active until the earliest timezone has access to that day's game.</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                            <Accordion.Header>[2024-05-27] Share Your Game Results with Emojis</Accordion.Header>
                            <Accordion.Body>
                                <p>When sharing your game results, you'll now see the quest number and emojis that represent your game! This is in addition to the link to your full game session.</p>
                                <p>Example below:</p>
                                <p className="mt-2">
                                    Quest10ns Quest #1 <br />
                                    🟩 🟪 🟥 🟩 <br />
                                    🟥 🟨 <br />
                                    https://quest10ns.com/session/abc
                                </p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                            <Accordion.Header>[2024-05-23] Better AI + Explanations</Accordion.Header>
                            <Accordion.Body>
                                <p>Thanks to everyone who provided feedback so far! It's clear that the AI was frequently leading people astray 😂.</p>
                                <p>After tweaking the AI, there should now be a noticeable improvement in the answers it gives. In addition, after finishing a game, you can click on the answer to see the AI's explanation.</p>
                                <Image className="w-100" src={process.env.REACT_APP_PUBLIC_URL + "explanations.png"} />
                                <p className="mt-4">If you still receive bad answers, let us know from the "Send Feedback" button in the "Person" icon dropdown!</p>
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                            <Accordion.Header>[2024-05-22] Welcome to Quest10ns!</Accordion.Header>
                            <Accordion.Body>
                                <p>And we're off! Quest10ns officially launches today, and we're glad you're here to have some fun. A few things to call out that are less obvious:</p>
                                <p>
                                    <ol>
                                        <li>
                                            Your game history will automatically be saved as long as you use the same device/browser. To save your game history across devices, click the "Person" icon in the top right to sign in with a Google account. This is completely optional to play the game.
                                        </li>
                                        <li className="mt-4">
                                            After playing the game, click the "Share Results" button to copy the link to your game session. Others with the link will be able to see exactly how your game went!
                                        </li>
                                        <li className="mt-4">
                                            If you have any feedback or encounter any bugs, click the "Send Feedback" button from the "Person" icon dropdown. The form is anonymous.
                                        </li>
                                        <li className="mt-4">
                                            You can view your game stats at <a target="_blank" href="https://questions.com/profile">questions.com/profile</a>. The "View Game Stats" button is also in the "Person" icon dropdown.
                                        </li>
                                    </ol>
                                </p>
                                <p>Happy questing!</p>
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                </Modal.Body>
            </Modal >
        </>
    );
}

export default AnnouncementsButton;