import { useContext, useState } from "react";
import { Dropdown, DropdownDivider, DropdownToggle, Modal, Toast, ToastContainer } from "react-bootstrap";
import { Person } from "react-bootstrap-icons";
import { GlobalStateContext, IGlobalStateContextValue } from "../../context/GlobalStateContext";
import { getAuth } from "firebase/auth";
import { getFirebaseApp } from "../../firebase/firebase-app";
import { UserInfo } from "../../common/user-info";
import FirebaseAuthContainer from "./FirebaseAuthContainer";

// Needed to access Termly's displayPreferenceModal() function.
declare global {
    interface Window {
        displayPreferenceModal: () => void;
    }
}

function AccountButton() {
    const {
        userInfo,
        showSignInModal,
        setUserInfo,
        setShowSignInModal,
        setShowTutorialModal,
    } = useContext<IGlobalStateContextValue | undefined>(GlobalStateContext)!;

    const firebaseAuth = getAuth(getFirebaseApp());

    const [showSuccessToast, setShowSuccessToast] = useState(false);
    const [successToastMessage, setSuccessToastMessage] = useState("");
    const [showErrorToast, setShowErrorToast] = useState(false);
    const [errorToastMessage, setErrorToastMessage] = useState("");

    const handleCloseModal = () => setShowSignInModal(false);
    const handleShowModal = () => {
        setShowSignInModal(true);
    };

    const handleSuccessfulLogin = (userId: string, email: string) => {
        setUserInfo(UserInfo.create(userId, email));
        setShowSuccessToast(true);
        setSuccessToastMessage("Successfully signed in! Refreshing the page.");
        setShowSignInModal(false);
        setTimeout(() => {
            window.location.reload();
        }, 1000);
    };

    const handleFailedLogin = (errorCode: string): void => {
        setShowErrorToast(true);
        setErrorToastMessage("There was an error signing you in (error code: \"" + errorCode + "\"). Please try again.");
    };

    const handleSignOut = () => {
        firebaseAuth.signOut().then(() => {
            setUserInfo(UserInfo.createEmpty());
            setShowSuccessToast(true);
            setSuccessToastMessage("Successfully signed out! Refreshing the page.");
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        }).catch((error) => {
            console.log(error);
            setShowErrorToast(true);
            setErrorToastMessage("There was an error signing you out. Please try again.");
        });
    };

    const openGameStats = () => {
        window.open(process.env.REACT_APP_PUBLIC_URL + "profile", "_blank");
    }

    const showTutorial = () => {
        setShowTutorialModal(true);
    }

    const openFeedbackLink = () => {
        window.open("https://docs.google.com/forms/d/e/1FAIpQLSeJpPvlqthfk-JLG1puz4Oyz-5Mk1cjHfFYVXc0RuFsKNx5GA/viewform", "_blank");
    }

    const openConsentPreferences = () => {
        window.displayPreferenceModal();
    };

    const openTerms = () => {
        window.open(process.env.REACT_APP_PUBLIC_URL + "terms", "_blank");
    }

    const openPrivacyPolicy = () => {
        window.open(process.env.REACT_APP_PUBLIC_URL + "terms", "_blank");
    }

    return (
        <>
            <Dropdown className="float-end ms-2" align="end">
                <DropdownToggle size="sm" variant="outline-primary">
                    <Person />
                </DropdownToggle >
                <Dropdown.Menu>
                    {!userInfo.isAuthenticated() &&
                        <div>
                            <Dropdown.Item onClick={handleShowModal}>Sign In</Dropdown.Item>
                            {userInfo.hasUser() && <Dropdown.Item onClick={openGameStats}>View Profile</Dropdown.Item>}
                        </div>
                    }
                    {userInfo.isAuthenticated() &&
                        <div>
                            <Dropdown.ItemText>Signed in with <strong>{userInfo.getEmail()}</strong></Dropdown.ItemText>
                            <Dropdown.Divider />
                            <Dropdown.Item onClick={openGameStats}>View Profile</Dropdown.Item>
                            <Dropdown.Item onClick={handleSignOut}>Sign Out</Dropdown.Item>
                        </div>
                    }
                    <DropdownDivider />
                    <Dropdown.Item onClick={showTutorial}>Tutorial</Dropdown.Item>
                    <Dropdown.Item onClick={openFeedbackLink}>Send Feedback</Dropdown.Item>
                    {window.displayPreferenceModal !== undefined &&
                        <Dropdown.Item onClick={openConsentPreferences}>Consent Preferences</Dropdown.Item>
                    }
                    <Dropdown.Item onClick={openTerms}>Terms of Use</Dropdown.Item>
                    <Dropdown.Item onClick={openPrivacyPolicy}>Privacy Policy</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>

            <Modal show={showSignInModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Sign In</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mb-4">Sign in below to save your game history across devices!</div>
                    <FirebaseAuthContainer currentUserInfo={userInfo} signInSuccessCallback={handleSuccessfulLogin} signInFailureCallback={handleFailedLogin} />
                    <div className="mt-4 fst-italic" style={{ fontSize: "0.8rem" }}>
                        We do not share or sell your personal data. By signing in, you are indicating that you accept our <a href={process.env.REACT_APP_PUBLIC_URL + "terms"} target="_blank">Terms of Use</a> and <a href={process.env.REACT_APP_PUBLIC_URL + "privacy"} target="_blank">Privacy Policy</a>.
                    </div>
                </Modal.Body>
            </Modal>

            <ToastContainer position="bottom-center" className="mb-5">
                <Toast onClose={() => setShowSuccessToast(false)} show={showSuccessToast} delay={2000} bg="success" autohide>
                    <Toast.Body className="text-white text-center">
                        {successToastMessage}
                    </Toast.Body>
                </Toast>
                <Toast onClose={() => setShowErrorToast(false)} show={showErrorToast} delay={5000} bg="danger" autohide>
                    <Toast.Body className="text-white text-center">
                        {errorToastMessage}
                    </Toast.Body>
                </Toast>
            </ToastContainer>
        </>
    );
}

export default AccountButton;