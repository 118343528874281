import { HumanGameStats } from "../../common/global-game-stats";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';

type Props = {
    humanGameStats: HumanGameStats
};

function GameResultDistribution(props: Props) {

    const labels = ["-"].concat(Array.from({ length: 10 }, (_, i) => i + 1).map(val => val.toString()));
    const barChartDataValues = [];
    for (const label of labels) {
        if (label === "-") {
            barChartDataValues.push(props.humanGameStats.getResultCountMap().get(-1));
        } else if (props.humanGameStats.getResultCountMap().has(Number(label))) {
            barChartDataValues.push(props.humanGameStats.getResultCountMap().get(Number(label)));
        } else {
            barChartDataValues.push(undefined);
        }
    }
    const barChartData = {
        labels: labels,
        datasets: [{
            axis: 'y',
            label: '# Games',
            data: barChartDataValues,
            fill: false,
            backgroundColor: [
                '#1846a6',
            ],
        }]
    };
    const barChartOptions = {
        indexAxis: 'y' as const,
        plugins: {
            datalabels: {
                color: "#fff",
            },
            legend: {
                display: false
            },
            tooltip: {
                enabled: false
            }
        }
    };

    return (
        <Bar data={barChartData} options={barChartOptions} plugins={[ChartDataLabels]} />
    );
}

export default GameResultDistribution;