import { useContext } from 'react';
import '../../styles/RobotContainer.scss';
import '../../styles/animations.scss';

import Image from 'react-bootstrap/Image';
import { GlobalStateContext, IGlobalStateContextValue } from '../../context/GlobalStateContext';
import { AskedQuestion } from '../../common/asked-question';
import { GameState } from '../../common/global-enums';
import LoadingIconContainer from '../shared/LoadingIconContainer';
import AnswerIconContainer from '../shared/AnswerIconContainer';
import ConfettiExplosion from 'react-confetti-explosion';
import HintQuestionContainer from './HintQuestionContainer';

type Props = {
    focusOnQueryInputRef: () => void,
};

// Displays the robot with the speech bubble while the game is ongoing.
function OngoingRobotContainer(props: Props) {
    const {
        gameState,
        validAskedQuestions,
        questionResponseMessage
    } = useContext<IGlobalStateContextValue | undefined>(GlobalStateContext)!;

    const lastResult: AskedQuestion | undefined =
        validAskedQuestions.length > 0 ? validAskedQuestions[validAskedQuestions.length - 1] : undefined;
    const showQuestionResponseMessage =
        gameState === GameState.AWAITING_USER_QUESTION &&
        questionResponseMessage.length > 0;
    const showAnswerIconContainer =
        (gameState === GameState.AWAITING_USER_QUESTION ||
            gameState === GameState.AWAITING_HINT_DISPLAY ||
            gameState === GameState.AWAITING_TRANSITION_TO_COMPLETED ||
            gameState === GameState.AWAITING_TRANSITION_TO_FAILED ||
            gameState === GameState.TRANSITIONING_TO_COMPLETED ||
            gameState === GameState.TRANSITIONING_TO_FAILED) &&
        lastResult !== undefined &&
        !showQuestionResponseMessage;
    const showConfetti = gameState === GameState.AWAITING_TRANSITION_TO_COMPLETED;
    const shakeRobot = gameState === GameState.AWAITING_TRANSITION_TO_FAILED || gameState === GameState.TRANSITIONING_TO_FAILED;

    return (
        <div className="robot-container">
            {showConfetti && <ConfettiExplosion style={{ margin: "auto" }} duration={2500} />}
            <div className={shakeRobot ? "image-container container-shake" : "image-container"}>
                <Image src={process.env.REACT_APP_PUBLIC_URL + 'happy-robot.webp'} className="w-100" />
            </div>
            <div className="speech-bubble">
                {gameState === GameState.NOT_STARTED && <div className="container-fade-in-down">Let's get started! Ask a question above.</div>}
                {gameState === GameState.AWAITING_ANSWER && <LoadingIconContainer />}
                {gameState === GameState.AWAITING_USER_HINT_RESPONSE && <HintQuestionContainer focusOnQueryInputRef={props.focusOnQueryInputRef} />}
                {showQuestionResponseMessage && <div className="container-fade-in-down">{questionResponseMessage}</div>}
                {showAnswerIconContainer &&
                    <div className="d-flex align-items-center justify-content-center h-100">
                        <div className="answer-icon-wrapper container-fade-in-down">
                            <AnswerIconContainer answerResult={lastResult.getNormalizedQuestionAnswerPair().getAnswer()} includeText={true} />
                        </div>
                    </div>}
            </div>
        </div>
    )
}

export default OngoingRobotContainer;