import { useContext, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { GlobalStateContext, IGlobalStateContextValue } from '../../context/GlobalStateContext';
import { Toast, ToastContainer } from 'react-bootstrap';
import { logEvent } from 'firebase/analytics';
import { getFirebaseAnalytics } from '../../firebase/firebase-app';
import { AnswerResult } from '../../common/global-enums';

function ShareButton() {
    const {
        gameId,
        gameSessionId,
        dailyWordNumber,
        validAskedQuestions,
    } = useContext<IGlobalStateContextValue | undefined>(GlobalStateContext)!;

    const url = process.env.REACT_APP_PUBLIC_URL + "session/" + gameSessionId;

    const [showCopyLinkToast, setShowCopyLinkToast] = useState(false);
    const copyLink = () => {
        navigator.clipboard.writeText(getTextToShare());
        setShowCopyLinkToast(true);
        recordShare("copy");
    };
    const recordShare = (method: string) => {
        logEvent(getFirebaseAnalytics(), "share", {
            method: method,
            item_id: gameId,
        });
    }

    const getTextToShare = () => {
        let result = "Quest10ns Quest #" + dailyWordNumber + "\n";
        result = result += getEmojiSquares() + "\n";
        result = result += url;
        return result;
    }

    const getEmojiSquares = () => {
        let result = "";
        for (let i = 0; i < validAskedQuestions.length; i++) {
            const askedQuestion = validAskedQuestions[i];
            if (askedQuestion.getNormalizedQuestionAnswerPair().getAnswer() === AnswerResult.YES) {
                result += "🟩";
            } else if (askedQuestion.getNormalizedQuestionAnswerPair().getAnswer() === AnswerResult.NO) {
                result += "🟥";
            } else if (askedQuestion.getNormalizedQuestionAnswerPair().getAnswer() === AnswerResult.MAYBE) {
                result += "🟪";
            } else if (askedQuestion.getNormalizedQuestionAnswerPair().getAnswer() === AnswerResult.CORRECT_GUESS) {
                result += "🟨";
            }
            if (i === 3 && validAskedQuestions.length > 4) {
                result += "\n";
            } else if (i === 7 && validAskedQuestions.length > 8) {
                result += "\n";
            } else if (i !== validAskedQuestions.length - 1) {
                result += " ";
            }
        }
        return result;
    }

    return (
        <>

            <Button variant="primary" className="w-100 mb-2" onClick={copyLink}>Share Results</Button>
            <ToastContainer position="bottom-center">
                <Toast onClose={() => setShowCopyLinkToast(false)} show={showCopyLinkToast} delay={1000} bg="success" autohide>
                    <Toast.Body className="text-white text-center">
                        Copied link!
                    </Toast.Body>
                </Toast>
            </ToastContainer>
        </>
    );
}

export default ShareButton;