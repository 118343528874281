type Props = {
    num: number,
    singularText: string,
    pluralText: string
};

function Plural(props: Props) {
    return (
        <>
            {props.num === 1
                ? props.singularText
                : props.pluralText}
        </>
    );
}

export default Plural;
