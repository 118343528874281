import '../../styles/animations.scss';

import { useContext } from 'react';
import Plural from '../shared/Plural';
import { IGlobalStateContextValue, GlobalStateContext } from '../../context/GlobalStateContext';

// Displays the number of questions the user has left.
function QuestionCounter() {
    const {
        numQuestionsAsked,
        numAttemptsAllowed
    } = useContext<IGlobalStateContextValue | undefined>(GlobalStateContext)!;
    const numQuestionsLeft: number = numAttemptsAllowed - numQuestionsAsked;
    let numberClass = numQuestionsLeft >= 5 ? "text-success" : numQuestionsLeft >= 2 ? "text-warning" : "text-danger";
    if (numQuestionsLeft === 0) {
        numberClass += " container-shake";
    }

    return (
        <div className="text-center fs-5">
            <span className={numberClass}><strong>{numQuestionsLeft}</strong></span> <Plural num={numQuestionsLeft} singularText="question" pluralText="questions"></Plural> remaining
            {numQuestionsLeft === 1 &&
                <span> - make a guess!</span>
            }
        </div>
    );
}

export default QuestionCounter;