import { Game, GameDto } from "./game";
import { UserGameResult } from "./global-enums";

// Represents a game that includes the user's result.
export class GameWithUserResult {
    private game: Game;
    private userGameResult: UserGameResult;
    private numQuestionsToWin: number;

    constructor(game: Game, userGameResult: UserGameResult, numQuestionsToWin: number) {
        this.game = game;
        this.userGameResult = userGameResult;
        this.numQuestionsToWin = numQuestionsToWin;
    }

    public static fromDto(dto: GameWithUserResultDto): GameWithUserResult {
        return new GameWithUserResult(Game.fromDto(dto.game), dto.userGameResult, dto.numQuestionsToWin);
    }

    public getGame(): Game {
        return this.game;
    }

    public getUserGameResult(): UserGameResult {
        return this.userGameResult;
    }

    public getNumQuestionsWon(): number {
        return this.numQuestionsToWin;
    }
}

// Data transfer object version of GameWithUserResult. 
export type GameWithUserResultDto = {
    game: GameDto;
    userGameResult: UserGameResult;
    numQuestionsToWin: number;
}