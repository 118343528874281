export const didGuessWordCorrectly = (query: string, secretWord: string, acceptedVariants: string[], acceptedThemeWords: string[]) => {
    const formattedQuery = getFormattedQuery(query, acceptedThemeWords);
    const formattedSecretWord = getFormattedSecretWord(secretWord);

    let result = formattedQuery === formattedSecretWord;
    if (!result) {
        // Check if the user guessed one of the accepted variants.
        for (let i = 0; i < acceptedVariants.length; i++) {
            const formattedAcceptedVariant = convertToKeyFormat(acceptedVariants[i]);
            if (formattedQuery === formattedAcceptedVariant) {
                result = true;
                break;
            }
        }
    }
    return result;
};

const getFormattedQuery = (query: string, acceptedThemeWords: string[]): string => {
    let formattedQuery = convertToKeyFormat(query);

    // Common starts of questions.
    if (formattedQuery.startsWith("is_")) {
        formattedQuery = formattedQuery.replace("is_", "_");
    }
    if (formattedQuery.startsWith("was_")) {
        formattedQuery = formattedQuery.replace("was_", "_");
    }
    if (formattedQuery.startsWith("are_")) {
        formattedQuery = formattedQuery.replace("are_", "_");
    }
    if (formattedQuery.startsWith("were_")) {
        formattedQuery = formattedQuery.replace("were_", "_");
    }
    // Parts of questions that typically goes with the common starts above.
    if (formattedQuery.includes("_it_")) {
        formattedQuery = formattedQuery.replace("_it_", "_");
    }
    if (formattedQuery.includes("_they_")) {
        formattedQuery = formattedQuery.replace("_they_", "_");
    }
    if (formattedQuery.includes("_the_")) {
        formattedQuery = formattedQuery.replace("_the_", "_");
    }
    if (formattedQuery.includes("_he_")) {
        formattedQuery = formattedQuery.replace("_he_", "_");
    }
    if (formattedQuery.includes("_she_")) {
        formattedQuery = formattedQuery.replace("_she_", "_");
    }
    if (formattedQuery.includes("_you_")) {
        formattedQuery = formattedQuery.replace("_you_", "_");
    }
    if (formattedQuery.includes("_this_")) {
        formattedQuery = formattedQuery.replace("_this_", "_");
    }
    if (formattedQuery.includes("_secret_word_")) {
        formattedQuery = formattedQuery.replace("_secret_word_", "_");
    }
    if (formattedQuery.includes("_word_")) {
        formattedQuery = formattedQuery.replace("_word_", "_");
    }
    if (formattedQuery.includes("_object_")) {
        formattedQuery = formattedQuery.replace("_object_", "_");
    }
    if (formattedQuery.includes("_thing_")) {
        formattedQuery = formattedQuery.replace("_thing_", "_");
    }
    if (formattedQuery.includes("_person_")) {
        formattedQuery = formattedQuery.replace("_person_", "_");
    }
    if (formattedQuery.includes("_item_")) {
        formattedQuery = formattedQuery.replace("_item_", "_");
    }
    if (formattedQuery.includes("_place_")) {
        formattedQuery = formattedQuery.replace("_place_", "_");
    }
    if (formattedQuery.includes("_related_to_")) {
        formattedQuery = formattedQuery.replace("_related_to_", "_");
    }
    if (formattedQuery.includes("_similar_to_")) {
        formattedQuery = formattedQuery.replace("_similar_to_", "_");
    }
    if (formattedQuery.includes("_same_as_")) {
        formattedQuery = formattedQuery.replace("_same_as_", "_");
    }
    if (formattedQuery.includes("_kind_of_")) {
        formattedQuery = formattedQuery.replace("_kind_of_", "_");
    }
    if (formattedQuery.includes("_type_of_")) {
        formattedQuery = formattedQuery.replace("_type_of_", "_");
    }
    if (formattedQuery.includes("_part_of_")) {
        formattedQuery = formattedQuery.replace("_part_of_", "_");
    }
    if (formattedQuery.includes("_a_")) {
        formattedQuery = formattedQuery.replace("_a_", "_");
    }
    if (formattedQuery.includes("_an_")) {
        formattedQuery = formattedQuery.replace("_an_", "_");
    }
    // Common ends of questions
    if (formattedQuery.endsWith("_related")) {
        formattedQuery = formattedQuery.replace("_related", "_");
    }
    // Accepted theme words
    for (const acceptedThemeWord of acceptedThemeWords) {
        const convertedAcceptedThemeWord = convertToKeyFormat(acceptedThemeWord);
        if (formattedQuery.includes("_" + convertedAcceptedThemeWord + "_")) {
            formattedQuery = formattedQuery.replace("_" + convertedAcceptedThemeWord + "_", "_");
        }
        if (formattedQuery.endsWith("_" + convertedAcceptedThemeWord)) {
            formattedQuery = formattedQuery.replace("_" + convertedAcceptedThemeWord, "_");
        }
    }

    // Consolidate multiple consecutive underscores into one.
    formattedQuery = formattedQuery.replace(/_+/g, '_');
    if (formattedQuery.startsWith("_")) {
        formattedQuery = formattedQuery.substring(1);
    }
    if (formattedQuery.endsWith("_")) {
        formattedQuery = formattedQuery.substring(0, formattedQuery.length - 1);
    }
    return formattedQuery;
}

const getFormattedSecretWord = (secretWord: string): string => {
    let formattedSecretWord = convertToKeyFormat(secretWord);

    if (formattedSecretWord.includes("the_")) {
        formattedSecretWord = formattedSecretWord.replace("the_", "_");
    }
    if (formattedSecretWord.includes("_the_")) {
        formattedSecretWord = formattedSecretWord.replace("_the_", "_");
    }

    // Consolidate multiple consecutive underscores into one.
    formattedSecretWord = formattedSecretWord.replace(/_+/g, '_');
    if (formattedSecretWord.startsWith("_")) {
        formattedSecretWord = formattedSecretWord.substring(1);
    }
    if (formattedSecretWord.endsWith("_")) {
        formattedSecretWord = formattedSecretWord.substring(0, formattedSecretWord.length - 1);
    }
    return formattedSecretWord;
}

export const convertToKeyFormat = (str: string): string => {
    return str.trim().toLowerCase()
        .replace(/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g, '')
        .replace(/\s+/g, "_");
}

export const capitalizeFirstLetter = (phrase: string): string => {
    if (phrase.length === 0) {
        return phrase;
    }
    const firstLetter = phrase.charAt(0);
    const firstLetterCap = firstLetter.toUpperCase();
    const remainingLetters = phrase.slice(1);
    return firstLetterCap + remainingLetters;
}