import { DateTime } from "luxon";
import { decode as base64Decode } from 'base-64';
import { decode as utf8Decode } from 'utf8';

// Represents a game.
export class Game {
    private gameId: string;
    private theme: string;
    private secretWord: string;
    private creatorUserId: string;
    private dailyWordNumber: number;
    private humanGameCount: number;
    private averageLlmQuestionsToWin: number;
    private timeCreated: DateTime;

    constructor(gameId: string, theme: string, secretWord: string, creatorUserId: string, dailyWordNumber: number, humanGameCount: number, averageLlmQuestionsToWin: number, timeCreated: DateTime) {
        this.gameId = gameId;
        this.theme = theme.charAt(0).toUpperCase() + theme.slice(1);
        this.secretWord = secretWord;
        this.creatorUserId = creatorUserId;
        this.dailyWordNumber = dailyWordNumber;
        this.humanGameCount = humanGameCount;
        this.averageLlmQuestionsToWin = averageLlmQuestionsToWin;
        this.timeCreated = timeCreated;
    }

    public static fromDto(dto: GameDto): Game {
        return new Game(dto.gameId, dto.theme, base64Decode(utf8Decode(dto.secretWord)), dto.creatorUserId, dto.dailyWordNumber, dto.humanGameCount, dto.averageLlmQuestionsToWin, DateTime.fromMillis(dto.timeCreatedMillis));
    }

    public getGameId(): string {
        return this.gameId;
    }

    public getDailyWordDate(): DateTime | undefined {
        if (this.gameId.startsWith("dailyword:")) {
            return DateTime.fromISO(this.gameId.substring(10));
        }
        return undefined;
    }

    public getTheme(): string {
        return this.theme;
    }

    public getSecretWord(): string {
        return this.secretWord;
    }

    public getCreatorUserId(): string {
        return this.creatorUserId;
    }

    public getDailyWordNumber(): number {
        return this.dailyWordNumber;
    }

    public getHumanGameCount(): number {
        return this.humanGameCount;
    }

    public getAverageLlmQuestionsToWin(): number {
        return this.averageLlmQuestionsToWin;
    }

    public getTimeCreated(): DateTime {
        return this.timeCreated;
    }
};

// Data transfer object version of Game. 
export type GameDto = {
    gameId: string;
    theme: string;
    secretWord: string;
    creatorUserId: string;
    dailyWordNumber: number;
    humanGameCount: number;
    averageLlmQuestionsToWin: number;
    timeCreatedMillis: number;
}