import { AnswerResult } from './global-enums';

// Contains a question and its corresponding answer.
export class NormalizedQuestionAnswerPair {
    /** Normalized key for the question. */
    private questionKey: string;
    private answer: AnswerResult;
    private explanation: string;

    constructor(questionKey: string, answer: AnswerResult, explanation: string) {
        this.questionKey = questionKey
        this.answer = answer;
        this.explanation = explanation;
    }

    public static fromDto(dto: NormalizedQuestionAnswerPairDto): NormalizedQuestionAnswerPair {
        return new NormalizedQuestionAnswerPair(dto.questionKey, dto.answer, dto.explanation);
    }

    public getQuestionKey(): string {
        return this.questionKey;
    }

    public getAnswer(): AnswerResult {
        return this.answer;
    }

    public getExplanation(): string {
        return this.explanation;
    }

    public toDto(): NormalizedQuestionAnswerPairDto {
        return { questionKey: this.questionKey, answer: this.answer, explanation: this.explanation };
    }
};

// Data transfer object version of NormalizedQuestionAnswerPair. 
export type NormalizedQuestionAnswerPairDto = {
    questionKey: string;
    answer: AnswerResult;
    explanation: string;
}

