import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import { getFirebaseApp } from './firebase-app';
import { GameWithUserResult, GameWithUserResultDto } from "../common/game-with-user-result";

/* Represents what's sent in the request to the Cloud function. */
interface FunctionRequest {
    userId: string;
    timezoneOffset: number;
}

/* Represents what's returned in the response from the Cloud function. */
interface FunctionResponse {
    games: GameWithUserResultDto[];
}

/** 
 * Calls the Cloud function to search games.
 */
export function getDailyGames(userId: string): Promise<GameWithUserResult[]> {
    const functions = getFunctions(getFirebaseApp());
    // Connect to local functions when running locally.
    if (process.env.NODE_ENV === "development") {
        connectFunctionsEmulator(functions, "localhost", 5001);
    }
    const getDailyGames = httpsCallable<FunctionRequest, FunctionResponse>(functions, 'getDailyGames');
    return getDailyGames({ userId, timezoneOffset: new Date().getTimezoneOffset() }).then((result) => {
        return result.data.games.map((gameWithUserResultDto) => GameWithUserResult.fromDto(gameWithUserResultDto));
    }).catch((error) => {
        console.log("Error while getting daily games from endpoint: " + error);
        throw error;
    });
}

