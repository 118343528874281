import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirebaseApp } from "../firebase/firebase-app";

enum UserType {
    NO_USER,
    ANONYMOUS,
    AUTHENTICATED
}

export class UserInfo {

    private type: UserType;
    private userId: string;
    private email: string;

    private constructor(userId: string, email: string) {
        if (userId.length > 0 && email.length > 0) {
            this.type = UserType.AUTHENTICATED;
        } else if (userId.length > 0) {
            this.type = UserType.ANONYMOUS;
        } else {
            this.type = UserType.NO_USER;
        }
        this.userId = userId;
        this.email = email;
    }

    public static create(userId: string, email: string): UserInfo {
        return new UserInfo(userId, email);
    }

    public static createUnauthenticated(userId: string): UserInfo {
        return new UserInfo(userId, "");
    }

    public static createEmpty(): UserInfo {
        return new UserInfo("", "");
    }

    public getUserId(): string {
        return this.userId;
    }

    public getEmail(): string {
        if (!this.isAuthenticated) {
            throw new Error("Can only get email of authenticated user.");
        }
        return this.email;
    }

    public hasUser(): boolean {
        return this.type !== UserType.NO_USER;
    }

    public isAnonymous(): boolean {
        return this.type === UserType.ANONYMOUS;
    }

    public isAuthenticated(): boolean {
        return this.type === UserType.AUTHENTICATED;
    }
}

export function getUserInfo(): Promise<UserInfo> {
    const firebaseAuth = getAuth(getFirebaseApp());
    return new Promise((resolve, _reject) => {
        onAuthStateChanged(firebaseAuth, (user) => {
            if (user) {
                if (user.isAnonymous) {
                    return resolve(UserInfo.createUnauthenticated(user.uid));
                }
                return resolve(UserInfo.create(user.uid, user.email!));
            }
            // We only create an anonymous user once a game session has been submitted.
            return resolve(UserInfo.createEmpty());
        });
    });
}