import { useContext } from "react";
import { GlobalStateContext, IGlobalStateContextValue } from "../../context/GlobalStateContext";
import { Alert, Col, Row } from "react-bootstrap";
import { TOP_LEVEL_COMPONENT_COL_LG, TOP_LEVEL_COMPONENT_COL_MD, TOP_LEVEL_COMPONENT_COL_XL, TOP_LEVEL_COMPONENT_COL_XXL } from "../../common/display-utils";

function SignedOutBanner() {
    const {
        userInfo,
        setShowSignInModal,
    } = useContext<IGlobalStateContextValue | undefined>(GlobalStateContext)!;

    if (!userInfo.isAnonymous()) {
        return <></>;
    }

    return (
        <Row className="justify-content-sm-center">
            <Col md={TOP_LEVEL_COMPONENT_COL_MD} lg={TOP_LEVEL_COMPONENT_COL_LG} xl={TOP_LEVEL_COMPONENT_COL_XL} xxl={TOP_LEVEL_COMPONENT_COL_XXL}>
                <Alert variant="warning">
                    Your game history will not carry over if you switch to a different device. <Alert.Link onClick={() => setShowSignInModal(true)}>Sign in</Alert.Link> to save your game history across devices.
                </Alert>
            </Col>
        </Row>
    );
}

export default SignedOutBanner;