import { NormalizedQuestionAnswerPair, NormalizedQuestionAnswerPairDto } from './normalized-question-answer-pair';


// Contains a frequently asked question.
export class FrequentlyAskedQuestion {
    private normalizedQuestionAnswerPair: NormalizedQuestionAnswerPair;
    private numAsked: number;
    private numMarkedAsMisleading: number;

    constructor(normalizedQuestionAnswerPair: NormalizedQuestionAnswerPair, numAsked: number, numMarkedAsMisleading: number) {
        this.normalizedQuestionAnswerPair = normalizedQuestionAnswerPair;
        this.numAsked = numAsked;
        this.numMarkedAsMisleading = numMarkedAsMisleading;
    }

    public static fromDto(dto: FrequentlyAskedQuestionDto): FrequentlyAskedQuestion {
        return new FrequentlyAskedQuestion(NormalizedQuestionAnswerPair.fromDto(dto.normalizedQuestionAnswerPair), dto.numAsked, dto.numMarkedAsMisleading);
    }

    public getRawQuestion(): string {
        return createQuestionFromKey(this.getNormalizedQuestionAnswerPair().getQuestionKey());
    }

    public getNormalizedQuestionAnswerPair(): NormalizedQuestionAnswerPair {
        return this.normalizedQuestionAnswerPair;
    }

    public getNumAsked(): number {
        return this.numAsked;
    }

    public getNumMarkedAsMisleading(): number {
        return this.numMarkedAsMisleading;
    }


    public toDto(): FrequentlyAskedQuestionDto {
        return { normalizedQuestionAnswerPair: this.normalizedQuestionAnswerPair.toDto(), numAsked: this.numAsked, numMarkedAsMisleading: this.numMarkedAsMisleading };
    }
};

// Data transfer object version of FrequentlyAskedQuestion. 
export type FrequentlyAskedQuestionDto = {
    normalizedQuestionAnswerPair: NormalizedQuestionAnswerPairDto;
    numAsked: number;
    numMarkedAsMisleading: number;
}

/** Returns a pretty version of the question key. */
function createQuestionFromKey(questionKey: string) {
    if (questionKey.length === 0) {
        return "";
    }
    let result = questionKey.replace(/_/g, " ");
    result = result.charAt(0).toUpperCase() + result.slice(1);
    result = result + "?";
    return result;
}