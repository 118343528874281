import { LlmAnswerResult } from '../common/global-enums';
import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import { getFirebaseApp } from '../firebase/firebase-app';
import { encode as base64Encode } from 'base-64';
import { encode as utf8Encode } from 'utf8';
import { LlmQuestionResponse } from '../common/llm-question-response';

/* Represents what's sent in the request to the Cloud function. */
interface FunctionRequest {
    // base64-encoded JSON object.
    params: string
}

/** 
 * Calls the Cloud function to get the answer to the user's question from the LLM.
 * 
 * Sends the params over as a base64-encoded JSON string so the secret word isn't sent in plaintext.
 */
export function getLlmAnswer(query: string, secretWord: string, theme: string, acceptedVariants: string[], acceptedThemeWords: string[]): Promise<LlmQuestionResponse> {
    const functions = getFunctions(getFirebaseApp());
    // Connect to local functions when running locally.
    if (process.env.NODE_ENV === "development") {
        connectFunctionsEmulator(functions, "127.0.0.1", 5001);
    }
    const getAnswer = httpsCallable<FunctionRequest, LlmQuestionResponse>(functions, 'getLlmAnswer');
    const paramsJson = JSON.stringify({
        secretWord: secretWord,
        query: query,
        theme: theme,
        acceptedVariants: acceptedVariants,
        acceptedThemeWords: acceptedThemeWords,
    });
    const encodedParamsJson = utf8Encode(paramsJson);
    const base64EncodedParams = base64Encode(encodedParamsJson);
    return getAnswer({ params: base64EncodedParams }).then((result) => {
        return result.data;
    }).catch((error) => {
        console.log("Error while getting answer from endpoint: " + error);
        return { answer: LlmAnswerResult.ERROR, explanation: "" };
    });
}
