import { LlmModelName } from "./global-enums";
import { LlmPlayerResult } from "./llm-player-result";
import { calculateScore } from "./score-utils";

// Stats for a game across all human and LLM players.
export class GlobalGameStats {
    private humanGameStats: HumanGameStats;
    private llmGameStats: LlmGameStats;

    constructor(humanGameStats: HumanGameStats, llmGameStats: LlmGameStats) {
        this.humanGameStats = humanGameStats;
        this.llmGameStats = llmGameStats;
    }

    public getHumanGameStats(): HumanGameStats {
        return this.humanGameStats;
    }

    public getLlmGameStats(): LlmGameStats {
        return this.llmGameStats;
    }
}

export class HumanGameStats {
    // Expressed out of 100, e.g. 95.5 to represent 95.5%.
    private winRate: number;
    private averageQuestionsToWin: number;
    private totalGames: number;
    private resultCountMap: Map<number, number>;
    private score: number;

    constructor(resultCountMap: Map<number, number>) {
        this.resultCountMap = resultCountMap;

        let wonGames = 0;
        let totalGames = 0;
        let totalQuestionsToWin = 0;
        for (const [questionsToWin, numGames] of resultCountMap) {
            totalGames += numGames;
            if (questionsToWin > 0) {
                wonGames += numGames;
                totalQuestionsToWin += (questionsToWin * numGames);
            }
        }

        this.totalGames = totalGames;
        this.winRate = totalGames === 0 ? 0 : Number(((wonGames / totalGames) * 100).toFixed(2));
        this.averageQuestionsToWin = wonGames === 0 ? 0 : Number((totalQuestionsToWin / wonGames).toFixed(2));
        this.score = calculateScore(this.winRate, this.averageQuestionsToWin);
    }

    public getWinRate(): number {
        return this.winRate;
    }

    public getAverageQuestionsToWin(): number {
        return this.averageQuestionsToWin;
    }

    public getTotalGames(): number {
        return this.totalGames;
    }

    public getResultCountMap(): Map<number, number> {
        return this.resultCountMap;
    }

    public getScore(): number {
        return this.score;
    }
}

export class LlmGameStats {
    private gameResults: Map<LlmModelName, LlmPlayerResult[]>;
    // Expressed out of 100, e.g. 95.5 to represent 95.5%.
    private winRate: number;
    private averageQuestionsToWin: number;
    private totalGames: number;
    private score: number;

    constructor(gameResults: Map<LlmModelName, LlmPlayerResult[]>) {
        this.gameResults = gameResults;

        let wonGames = 0;
        let totalGames = 0;
        let totalQuestionsToWin = 0;
        for (const llmPlayerResults of gameResults.values()) {
            for (const llmPlayerResult of llmPlayerResults) {
                totalGames++;
                if (llmPlayerResult.resultCount > 0) {
                    wonGames++;
                    totalQuestionsToWin += llmPlayerResult.resultCount;
                }
            }
        }

        this.winRate = totalGames === 0 ? 0 : Number(((wonGames / totalGames) * 100).toFixed(2));
        this.averageQuestionsToWin = wonGames === 0 ? 0 : Number((totalQuestionsToWin / wonGames).toFixed(2));
        this.totalGames = totalGames;
        this.score = calculateScore(this.winRate, this.averageQuestionsToWin);
    }

    public getGameResults(): Map<LlmModelName, LlmPlayerResult[]> {
        return this.gameResults;
    }

    public getWinRate(): number {
        return this.winRate;
    }

    public getAverageQuestionsToWin(): number {
        return this.averageQuestionsToWin;
    }

    public getTotalGames(): number {
        return this.totalGames;
    }

    public getScore(): number {
        return this.score;
    }
}