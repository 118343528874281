import { useState } from 'react';
import {
  useParams,
} from "react-router-dom";
import Header from './components/shared/Header';
import GlobalStateProvider from './context/GlobalStateContext';
import Main from './Main';
import { getWordDetailsForGame } from './firebase/get-word-details-for-game-wrapper';
import PageLoadingScreen from './components/PageLoadingScreen';
import { AnswerResult, GameState } from './common/global-enums';
import { UserInfo, getUserInfo } from './common/user-info';
import { Hint } from './common/hint';
import { GameTranscriptRecord, GameTranscriptRecordType } from './common/game-transcript-record';
import { AskedQuestion } from './common/asked-question';
import { GlobalGameStats } from './common/global-game-stats';
import ErrorScreen, { ErrorScreenType } from './components/shared/ErrorScreen';
import Footer from './components/shared/Footer';
import { FrequentlyAskedQuestion } from './common/frequently-asked-question';

function App() {
  const { gameId } = useParams();

  const [isPageLoading, setIsPageLoading] = useState(true);
  const [secretWord, setSecretWord] = useState("");
  const [acceptedVariants, setAcceptedVariants] = useState<string[]>([]);
  const [acceptedThemeWords, setAcceptedThemeWords] = useState<string[]>([]);
  const [faq, setFAQ] = useState<FrequentlyAskedQuestion[]>([]);
  const [initialGameId, setInitialGameId] = useState(gameId ?? "");
  const [initialGameState, setInitialGameState] = useState(GameState.NOT_STARTED);
  const [initialGameTranscriptRecords, setInitialGameTranscriptRecords] = useState<GameTranscriptRecord[]>([]);
  const [initialHints, setInitialHints] = useState<Hint[]>([]);
  const [initialGameSessionId, setInitialGameSessionId] = useState("");
  const [initialUserInfo, setInitialUserInfo] = useState<UserInfo>(UserInfo.createEmpty());
  const [theme, setTheme] = useState("");
  const [dailyWordNumber, setDailyWordNumber] = useState(0);
  const [globalGameStats, setGlobalGameStats] = useState<GlobalGameStats>();
  const [currentWinStreak, setCurrentWinStreak] = useState<number>(0);
  const [errorScreenType, setErrorScreenType] = useState<ErrorScreenType>();

  if (isPageLoading) {
    getUserInfo().then((userInfo: UserInfo) => {
      getWordDetailsForGame(userInfo.getUserId(), gameId).then(wordDetails => {
        setSecretWord(wordDetails.secretWord);
        setInitialGameId(wordDetails.gameId);
        setAcceptedVariants(wordDetails.acceptedVariants);
        setAcceptedThemeWords(wordDetails.acceptedThemeWords);
        setFAQ(wordDetails.faq);
        setTheme(wordDetails.theme);
        setDailyWordNumber(wordDetails.dailyWordNumber);
        setInitialHints(wordDetails.hints);
        setGlobalGameStats(wordDetails.globalGameStats);
        // The transcript only comes back for a completed game.
        if (wordDetails.transcriptRecords.length > 0) {
          setInitialGameTranscriptRecords(wordDetails.transcriptRecords);
          setInitialGameSessionId(wordDetails.gameSessionId);
          const askedQuestions: AskedQuestion[] = wordDetails.transcriptRecords.filter(record => record.getType() === GameTranscriptRecordType.AskedQuestion).map(record => record.getAskedQuestion());
          if (askedQuestions.length >= 10 && askedQuestions[askedQuestions.length - 1].getNormalizedQuestionAnswerPair().getAnswer() !== AnswerResult.CORRECT_GUESS) {
            setInitialGameState(GameState.FAILED);
          } else {
            setInitialGameState(GameState.COMPLETED);
          }
        }
        setInitialUserInfo(userInfo);
        setCurrentWinStreak(wordDetails.currentWinStreak);
        setIsPageLoading(false);
      }).catch((error) => {
        if (error.code === "functions/not-found") {
          setErrorScreenType(ErrorScreenType.NOT_FOUND);
        } else {
          setErrorScreenType(ErrorScreenType.GENERIC_ERROR);
        }
      });
    }).catch((error) => {
      if (error.code === "functions/not-found") {
        setErrorScreenType(ErrorScreenType.NOT_FOUND);
      } else {
        setErrorScreenType(ErrorScreenType.GENERIC_ERROR);
      }
    });
  }

  return (
    <>
      {isPageLoading && errorScreenType === undefined && <PageLoadingScreen />}
      {!isPageLoading && errorScreenType === undefined &&
        <GlobalStateProvider
          acceptedVariants={acceptedVariants}
          acceptedThemeWords={acceptedThemeWords}
          faq={faq}
          globalGameStats={globalGameStats!}
          gameId={initialGameId}
          secretWord={secretWord}
          theme={theme}
          dailyWordNumber={dailyWordNumber}
          playerUserId={""}
          initialGameState={initialGameState}
          initialGameTranscriptRecords={initialGameTranscriptRecords}
          initialHints={initialHints}
          initialGameSessionId={initialGameSessionId}
          initialUserInfo={initialUserInfo}
          initialCurrentWinStreak={currentWinStreak}
        >
          <Header />
          <Main />
          <Footer />
        </GlobalStateProvider>
      }
      {errorScreenType !== undefined && <ErrorScreen type={errorScreenType} />}
    </>
  );
}

export default App;
