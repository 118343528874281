import GameTranscript from '../transcript/GameTranscript';
import GameStats from './GameStats';
import FinishedRobotContainer from './FinishedRobotContainer';


// Handles the experience after the user has won or lost the game.
function FinishedScreen() {
    return (
        <>
            <div className="mt-4">
                <FinishedRobotContainer />
            </div>
            <div className="mt-4">
                <GameStats />
            </div>
            <div className="mt-4 mb-4">
                <GameTranscript reverse={false} showExplanations={true} canMarkAsMisleading={true} />
            </div>
        </>
    );
}

export default FinishedScreen;