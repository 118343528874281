import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import { getFirebaseApp } from './firebase-app';
import { decode as base64Decode } from 'base-64';
import { decode as utf8Decode } from 'utf8';
import { Hint, HintDto } from '../common/hint';
import { GameTranscriptRecord, GameTranscriptRecordDto } from '../common/game-transcript-record';
import { GlobalGameStats, HumanGameStats, LlmGameStats } from '../common/global-game-stats';
import { LlmModelName } from '../common/global-enums';
import { LlmPlayerResult } from '../common/llm-player-result';
import { FrequentlyAskedQuestion, FrequentlyAskedQuestionDto } from '../common/frequently-asked-question';

/* Represents what's sent in the request to the Cloud function. */
interface FunctionRequest {
    userId: string,
    gameId?: string,
    timezoneOffset: number,
}

/* Represents what's returned in the response from the Cloud function. */
interface FunctionResponse {
    // base64-encoded JSON object.
    response: string;
}

/** Struct to hold data from the getWordDetailsForGame() method. */
export type WordDetails = {
    secretWord: string;
    gameId: string;
    dailyWordNumber: number;
    acceptedVariants: string[];
    acceptedThemeWords: string[];
    faq: FrequentlyAskedQuestion[],
    transcriptRecords: GameTranscriptRecord[],
    gameSessionId: string,
    theme: string,
    globalGameStats: GlobalGameStats,
    hints: Hint[],
    currentWinStreak: number,
}

/** 
 * Calls the Cloud function to get the answer to the user's question from the LLM.
 * 
 * The response is encoded as a base64-encoded JSON string so the secret word isn't sent in plaintext.
 */
export function getWordDetailsForGame(userId: string, gameId?: string): Promise<WordDetails> {
    const functions = getFunctions(getFirebaseApp());
    // Connect to local functions when running locally.
    if (process.env.NODE_ENV === "development") {
        connectFunctionsEmulator(functions, "localhost", 5001);
    }
    const getWordDetailsForGame = httpsCallable<FunctionRequest, FunctionResponse>(functions, 'getWordDetailsForGame');
    return getWordDetailsForGame({ userId: userId, gameId: gameId, timezoneOffset: new Date().getTimezoneOffset() }).then((result) => {
        const responseStr = base64Decode(result.data.response);
        const decodedResponseStr = utf8Decode(responseStr);
        const response = JSON.parse(decodedResponseStr);

        const humanResultCountMap: Map<number, number> = new Map();
        Object.keys(response.humanGameStats.resultCountMap).forEach((key) => {
            humanResultCountMap.set(Number(key), response.humanGameStats.resultCountMap[key]);
        });
        const humanGameStats = new HumanGameStats(humanResultCountMap);
        let llmPlayerSummary: Map<LlmModelName, LlmPlayerResult[]> = new Map();
        Object.keys(response.llmGameStats).forEach((key) => {
            llmPlayerSummary.set(key as LlmModelName, response.llmGameStats[key]);
        });
        const llmGameStats = new LlmGameStats(llmPlayerSummary);

        const wordDetails: WordDetails = {
            secretWord: response.word,
            gameId: response.gameId,
            dailyWordNumber: response.dailyWordNumber,
            acceptedVariants: response.acceptedVariants ?? [],
            acceptedThemeWords: response.acceptedThemeWords ?? [],
            faq: response.faq?.map((faqDto: FrequentlyAskedQuestionDto) => FrequentlyAskedQuestion.fromDto(faqDto)) ?? [],
            transcriptRecords: response.transcriptRecords?.map((recordDto: GameTranscriptRecordDto) => GameTranscriptRecord.fromDto(recordDto)) ?? [],
            gameSessionId: response.gameSessionId ?? "",
            theme: response.theme ?? "",
            globalGameStats: new GlobalGameStats(humanGameStats, llmGameStats),
            hints: response.hints?.map((hintDto: HintDto) => Hint.fromDto(hintDto)) ?? [],
            currentWinStreak: response.currentWinStreak,
        };
        return wordDetails;
    }).catch((error) => {
        console.log("Error while getting answer from endpoint: " + error);
        throw error;
    });
}

