import '../../styles/LoadingIconContainer.scss';

// Container for a loading icon.
function LoadingIconContainer() {
    return (
        <div className={"h-100 d-flex align-items-center justify-content-center"}>
            <div className="dot-flashing"></div>
        </div>
    );
}

export default LoadingIconContainer;