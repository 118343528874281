import { useState } from "react";
import { UserInfo, getUserInfo } from "./common/user-info";
import { getUserStats } from "./firebase/get-user-stats-wrapper";
import { UserStats } from "./common/user-stats";
import ErrorScreen, { ErrorScreenType } from "./components/shared/ErrorScreen";
import PageLoadingScreen from "./components/PageLoadingScreen";
import Header from "./components/shared/Header";
import { Card, Col, Container, Row, Tab, Tabs } from "react-bootstrap";
import Footer from "./components/shared/Footer";
import GlobalStateProvider from "./context/GlobalStateContext";
import { GameState } from "./common/global-enums";
import ProfileStats from "./components/profile/ProfileStats";
import SignedOutBanner from "./components/profile/SignedOutBanner";
import UserCreatedGames from "./components/profile/UserCreatedGames";

const ProfilePage = (): React.ReactElement => {
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [userStats, setUserStats] = useState<UserStats>();
    const [initialUserInfo, setInitialUserInfo] = useState<UserInfo>(UserInfo.createEmpty());
    const [errorScreenType, setErrorScreenType] = useState<ErrorScreenType>();

    if (isPageLoading) {
        getUserInfo().then((userInfo: UserInfo) => {
            getUserStats(userInfo.getUserId()).then((userStats: UserStats) => {
                setUserStats(userStats);
                setIsPageLoading(false);
                setInitialUserInfo(userInfo);
            }).catch((error) => {
                if (error.code === "functions/not-found") {
                    setErrorScreenType(ErrorScreenType.NOT_FOUND);
                } else {
                    setErrorScreenType(ErrorScreenType.GENERIC_ERROR);
                }
            });
        }).catch((error) => {
            if (error.code === "functions/not-found") {
                setErrorScreenType(ErrorScreenType.NOT_FOUND);
            } else {
                setErrorScreenType(ErrorScreenType.GENERIC_ERROR);
            }
        });

    }

    return (
        <>
            {isPageLoading && errorScreenType === undefined && <PageLoadingScreen />}
            {
                !isPageLoading && errorScreenType === undefined &&
                <GlobalStateProvider
                    acceptedVariants={[]}
                    acceptedThemeWords={[]}
                    faq={[]}
                    globalGameStats={undefined}
                    gameId={""}
                    secretWord={""}
                    theme={""}
                    dailyWordNumber={0}
                    playerUserId={""}
                    initialGameState={GameState.NOT_STARTED}
                    initialGameTranscriptRecords={[]}
                    initialHints={[]}
                    initialGameSessionId={""}
                    initialUserInfo={initialUserInfo}
                    initialCurrentWinStreak={0}
                >
                    <Header />
                    <Container fluid="sm">
                        <div className="mt-4">
                            <SignedOutBanner />
                            <Row className="justify-content-sm-center">
                                <Col lg={{ span: 10 }} xl={{ span: 9 }} xxl={{ span: 8 }}>
                                    <Card>
                                        <Card.Header>Your Quest10ns Profile</Card.Header>
                                        <Card.Body>
                                            <Tabs
                                                defaultActiveKey="stats">
                                                <Tab eventKey="stats" title="Stats">
                                                    <ProfileStats userStats={userStats!} />
                                                </Tab>
                                                <Tab eventKey="createdGames" title="Created Quests">
                                                    <UserCreatedGames userStats={userStats!} />
                                                </Tab>
                                            </Tabs>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                    <Footer />
                </GlobalStateProvider>
            }
            {errorScreenType !== undefined && <ErrorScreen type={errorScreenType} />}

        </>

    );
}

export default ProfilePage;