// Hint question for the game.
export class Hint {
    private question: string;
    private answerIndex: number;
    private choices: string[];
    private bonusInfo: string;

    constructor(question: string, answerIndex: number, choices: string[], bonusInfo: string) {
        this.question = question;
        this.answerIndex = answerIndex;
        this.choices = choices;
        this.bonusInfo = bonusInfo;
    }

    public static fromDto(dto: HintDto): Hint {
        return new Hint(dto.question, dto.answerIndex, dto.choices, dto.bonusInfo);
    }

    public getQuestion(): string {
        return this.question;
    }

    public getAnswerIndex(): number {
        return this.answerIndex;
    }

    public getAnswer(): string {
        return this.choices[this.answerIndex];
    }

    public getChoices(): string[] {
        return this.choices;
    }

    public getBonusInfo(): string {
        return this.bonusInfo;
    }

    public toDto(): HintDto {
        return { question: this.question, answerIndex: this.answerIndex, choices: this.choices, bonusInfo: this.bonusInfo };
    }
};

// Data transfer object version of Hint. 
export type HintDto = {
    question: string;
    answerIndex: number;
    choices: string[];
    bonusInfo: string;
}