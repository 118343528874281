import { Image } from 'react-bootstrap';
import '../../styles/RobotContainer.scss';
import Header from './Header';

export enum ErrorScreenType {
    NOT_FOUND,
    GENERIC_ERROR
}

type Props = {
    type: ErrorScreenType
};

function ErrorScreen(props: Props) {
    const errorMessage =
        props.type === ErrorScreenType.NOT_FOUND ?
            "This page doesn't exist. Double-check the URL!" :
            "Oops! I had an issue on my end. Please try again in a bit.";

    return <>
        <Header onlyShowBrand={true} />


        <div className="position-absolute top-50 start-50 translate-middle">
            <div className="robot-container">
                <div className="image-container">
                    <Image src={process.env.REACT_APP_PUBLIC_URL + 'happy-robot.webp'} className="w-100" />
                </div>
                <div className="speech-bubble">
                    <div className="container-fade-in-down">{errorMessage}</div>
                </div>
            </div>
        </div>
    </>
}

export default ErrorScreen;
