import { Col, OverlayTrigger, Popover, Row, Table } from "react-bootstrap";
import { UserStats } from "../../common/user-stats";
import { QuestionCircle } from "react-bootstrap-icons";
import 'chart.js/auto';
import { Bar } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';

type Props = {
    userStats: UserStats
};

function ProfileStats(props: Props) {
    const scorePopover = (
        <Popover id="score-popover">
            <Popover.Header>Quest10n Score Definition</Popover.Header>
            <Popover.Body>
                <p>The score is calculated using:</p>
                <p>
                    (11 - [Avg # of questions to win]) * [Win rate]
                </p>
            </Popover.Body>
        </Popover>
    );

    const labels = ["-"].concat(Array.from({ length: 10 }, (_, i) => i + 1).map(val => val.toString()));
    const barChartDataValues = [];
    for (const label of labels) {
        if (label === "-") {
            barChartDataValues.push(props.userStats.getDailyWordResultCountMap().get(-1));
        } else if (props.userStats.getDailyWordResultCountMap().has(Number(label))) {
            barChartDataValues.push(props.userStats.getDailyWordResultCountMap().get(Number(label)));
        } else {
            barChartDataValues.push(undefined);
        }
    }
    const barChartData = {
        labels: labels,
        datasets: [{
            axis: 'y',
            label: '# Games',
            data: barChartDataValues,
            fill: false,
            backgroundColor: [
                '#1846a6',
            ],
        }]
    };
    const barChartOptions = {
        indexAxis: 'y' as const,
        plugins: {
            datalabels: {
                color: "#fff",
            },
            legend: {
                display: false
            },
            tooltip: {
                enabled: false
            }
        }
    };

    return (
        <Row className="justify-content-sm-center mt-4">
            <Col>
                <Row>
                    <Col>
                        <Table bordered striped>
                            <tbody>
                                <tr>
                                    <td>Current Win Streak</td>
                                    <td>{props.userStats.getCurrentWinStreak()}</td>
                                </tr>
                                <tr>
                                    <td>Max Win Streak</td>
                                    <td>{props.userStats.getMaxWinStreak()}</td>
                                </tr>
                                <tr>
                                    <td>Games Played</td>
                                    <td>{props.userStats.getTotalGamesPlayed()}</td>
                                </tr>
                                <tr>
                                    <td>Games Won</td>
                                    <td>{props.userStats.getGamesWon()}</td>
                                </tr>
                                <tr>
                                    <td>Win rate</td>
                                    <td>{props.userStats.getWinRate()}%</td>
                                </tr>
                                <tr>
                                    <td>Avg # of questions to win</td>
                                    <td>{props.userStats.getAverageQuestionsToWin()}</td>
                                </tr>
                                <tr>
                                    <td><strong>Avg Quest10n Score</strong> <OverlayTrigger trigger="click" placement="top" rootClose overlay={scorePopover}><QuestionCircle className="combined-score-question" /></OverlayTrigger></td>
                                    <td><strong>{props.userStats.getScore()}</strong></td>
                                </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h2 className="text-center">Guess Distribution</h2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Bar className="mx-auto w-75 h-100" data={barChartData} options={barChartOptions} plugins={[ChartDataLabels]} />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

export default ProfileStats;