import { useContext } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import { IGlobalStateContextValue, GlobalStateContext } from '../../context/GlobalStateContext';
import { GameTranscriptRecord, GameTranscriptRecordType } from '../../common/game-transcript-record';
import QuestionTranscriptRow from './QuestionTranscriptRow';
import HintTranscriptRow from './HintTranscriptRow';
import { TOP_LEVEL_COMPONENT_COL_LG, TOP_LEVEL_COMPONENT_COL_MD, TOP_LEVEL_COMPONENT_COL_XL, TOP_LEVEL_COMPONENT_COL_XXL } from '../../common/display-utils';

type Props = {
    // Whether to display the latest questions first.
    reverse: boolean;
    // Whether to show the explanations for each question.
    showExplanations: boolean;
    // Whether you can mark an answer as misleading.
    canMarkAsMisleading: boolean;
};

// Displays the transcript of questions the user has asked.
function GameTranscript(props: Props) {
    const {
        gameTranscriptRecords,
    } = useContext<IGlobalStateContextValue | undefined>(GlobalStateContext)!;
    if (gameTranscriptRecords.length === 0) {
        return null;
    }
    let sortedGameTranscriptRecords = [...gameTranscriptRecords];
    if (props.reverse) {
        sortedGameTranscriptRecords.reverse();
    }
    const askedQuestionIndexMap = createAskedQuestionIndexMap(sortedGameTranscriptRecords);

    return (
        <Row className="justify-content-sm-center">
            <Col md={TOP_LEVEL_COMPONENT_COL_MD} lg={TOP_LEVEL_COMPONENT_COL_LG} xl={TOP_LEVEL_COMPONENT_COL_XL} xxl={TOP_LEVEL_COMPONENT_COL_XXL}>
                <div className="border border-2 rounded">
                    <Table style={{ margin: "0" }}>
                        <tbody>
                            {sortedGameTranscriptRecords.map((gameTranscriptRecord, index) => (
                                <tr key={index}>
                                    {gameTranscriptRecord.getType() === GameTranscriptRecordType.AskedQuestion &&
                                        <QuestionTranscriptRow
                                            askedQuestion={gameTranscriptRecord.getAskedQuestion()}
                                            index={askedQuestionIndexMap.get(gameTranscriptRecord.getAskedQuestion().getNormalizedQuestionAnswerPair().getQuestionKey())!}
                                            reverse={props.reverse}
                                            showExplanation={props.showExplanations}
                                            canMarkAsMisleading={props.canMarkAsMisleading} />}
                                    {gameTranscriptRecord.getType() === GameTranscriptRecordType.HintResponse &&
                                        <HintTranscriptRow hintResponse={gameTranscriptRecord.getHintResponse()} />}
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </Col>
        </Row>
    );
}

// Creates a map from the question key to the index that should be displayed.
const createAskedQuestionIndexMap = (records: GameTranscriptRecord[]): Map<string, number> => {
    return new Map(
        records.filter(record => record.getType() === GameTranscriptRecordType.AskedQuestion)
            .map((record, index) => [record.getAskedQuestion().getNormalizedQuestionAnswerPair().getQuestionKey(), index]));
}

export default GameTranscript;