import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import { getFirebaseApp } from './firebase-app';
import { decode as base64Decode } from 'base-64';
import { decode as utf8Decode } from 'utf8';
import { GameTranscriptRecord, GameTranscriptRecordDto } from '../common/game-transcript-record';
import { GlobalGameStats, HumanGameStats, LlmGameStats } from "../common/global-game-stats";
import { LlmModelName } from "../common/global-enums";
import { LlmPlayerResult } from "../common/llm-player-result";

/* Represents what's sent in the request to the Cloud function. */
interface FunctionRequest {
    gameSessionId: string
}

/* Represents what's returned in the response from the Cloud function. */
interface FunctionResponse {
    // base64-encoded JSON object.
    response: string;
}

/** Struct to hold data from the getSessionResult() method. */
export type WordDetails = {
    gameId: string;
    secretWord: string;
    theme: string;
    dailyWordNumber: number;
    acceptedVariants: string[];
    transcriptRecords: GameTranscriptRecord[];
    gameSessionId: string;
    globalGameStats: GlobalGameStats;
    playerUserId: string;
}

/** 
 * Calls the Cloud function to get the word details for a game session.
 * 
 * The response is encoded as a base64-encoded JSON string so the secret word isn't sent in plaintext.
 */
export function getSessionResult(gameSessionId: string): Promise<WordDetails> {
    const functions = getFunctions(getFirebaseApp());
    // Connect to local functions when running locally.
    if (process.env.NODE_ENV === "development") {
        connectFunctionsEmulator(functions, "localhost", 5001);
    }
    const getSessionResult = httpsCallable<FunctionRequest, FunctionResponse>(functions, 'getSessionResult');
    return getSessionResult({ gameSessionId }).then((result) => {
        const responseStr = base64Decode(result.data.response);
        const decodedResponseStr = utf8Decode(responseStr);
        const response = JSON.parse(decodedResponseStr);

        const humanResultCountMap: Map<number, number> = new Map();
        Object.keys(response.humanGameStats.resultCountMap).forEach((key) => {
            humanResultCountMap.set(Number(key), response.humanGameStats.resultCountMap[key]);
        });
        const humanGameStats = new HumanGameStats(humanResultCountMap);
        let llmPlayerSummary: Map<LlmModelName, LlmPlayerResult[]> = new Map();
        Object.keys(response.llmGameStats).forEach((key) => {
            llmPlayerSummary.set(key as LlmModelName, response.llmGameStats[key]);
        });
        const llmGameStats = new LlmGameStats(llmPlayerSummary);

        const wordDetails: WordDetails = {
            gameId: response.gameId,
            secretWord: response.word,
            theme: response.theme,
            dailyWordNumber: response.dailyWordNumber,
            acceptedVariants: response.acceptedVariants || [],
            transcriptRecords: response.transcriptRecords?.map((recordDto: GameTranscriptRecordDto) => GameTranscriptRecord.fromDto(recordDto)) || [],
            gameSessionId: response.gameSessionId || "",
            globalGameStats: new GlobalGameStats(humanGameStats, llmGameStats),
            playerUserId: response.playerUserId || "",
        };
        return wordDetails;
    }).catch((error) => {
        console.log("Error while getting session result from endpoint: " + error);
        throw error;
    });
}

