import { AskedQuestion, AskedQuestionDto } from "./asked-question";
import { HintResponse, HintResponseDto } from "./hint-response";

export enum GameTranscriptRecordType {
    AskedQuestion,
    HintResponse,
}

// Represents a user event that occurred in the game.
export class GameTranscriptRecord {
    private type: GameTranscriptRecordType;
    private askedQuestion: AskedQuestion | undefined;
    private hintResponse: HintResponse | undefined;

    private constructor(askedQuestion: AskedQuestion | undefined, hintResponse: HintResponse | undefined) {
        this.type = askedQuestion ? GameTranscriptRecordType.AskedQuestion : GameTranscriptRecordType.HintResponse;
        this.askedQuestion = askedQuestion;
        this.hintResponse = hintResponse;
    }

    public static forAskedQuestion(askedQuestion: AskedQuestion): GameTranscriptRecord {
        return new GameTranscriptRecord(askedQuestion, undefined);
    }

    public static forHintResponse(hintResponse: HintResponse): GameTranscriptRecord {
        return new GameTranscriptRecord(undefined, hintResponse);
    }

    public static fromDto(dto: GameTranscriptRecordDto): GameTranscriptRecord {
        return new GameTranscriptRecord(
            dto.askedQuestion ? AskedQuestion.fromDto(dto.askedQuestion) : undefined,
            dto.hintResponse ? HintResponse.fromDto(dto.hintResponse) : undefined,
        );
    }

    public getType(): GameTranscriptRecordType {
        return this.type;
    }

    public getAskedQuestion(): AskedQuestion {
        if (this.type !== GameTranscriptRecordType.AskedQuestion) {
            throw new Error("Invalid type while getAskedQuestion() was called: " + this.type);
        }
        return this.askedQuestion!;
    }

    public getHintResponse(): HintResponse {
        if (this.type !== GameTranscriptRecordType.HintResponse) {
            throw new Error("Invalid type while getHintResponse() was called: " + this.type);
        }
        return this.hintResponse!;
    }

    public toDto(): GameTranscriptRecordDto {
        return { type: this.type, askedQuestion: this.askedQuestion?.toDto(), hintResponse: this.hintResponse?.toDto() };
    }
}

// Data transfer object version of GameTranscriptRecord. 
export type GameTranscriptRecordDto = {
    type: GameTranscriptRecordType;
    askedQuestion: AskedQuestionDto | undefined;
    hintResponse: HintResponseDto | undefined;
}