import React from 'react';
import Container from 'react-bootstrap/Container';
import GameTranscript from './components/transcript/GameTranscript';
import {
  useParams,
} from "react-router-dom";
import { useState } from 'react';
import Header from './components/shared/Header';
import GlobalStateProvider from './context/GlobalStateContext';
import { getSessionResult } from './firebase/get-session-result-wrapper';
import PageLoadingScreen from './components/PageLoadingScreen';
import { AnswerResult, GameState } from './common/global-enums';
import { UserInfo, getUserInfo } from './common/user-info';
import { GameTranscriptRecord, GameTranscriptRecordType } from './common/game-transcript-record';
import { GlobalGameStats } from './common/global-game-stats';
import { AskedQuestion } from './common/asked-question';
import GameStats from './components/finished/GameStats';
import FinishedSummaryCard from './components/finished/FinishedSummaryCard';
import ErrorScreen, { ErrorScreenType } from './components/shared/ErrorScreen';
import Footer from './components/shared/Footer';
import SpoilerConfirmation from './components/shared/SpoilerConfirmation';
import { alreadyConfirmedSpoiler, setConfirmedSpoiler } from './common/spoiler-utils';

const SessionPage = (): React.ReactElement => {
  const { sessionId } = useParams();

  const [isPageLoading, setIsPageLoading] = useState(true);
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const [gameId, setGameId] = useState("");
  const [secretWord, setSecretWord] = useState("");
  const [theme, setTheme] = useState("");
  const [dailyWordNumber, setDailyWordNumber] = useState(0);
  const [playerUserId, setPlayerUserId] = useState("");
  const [initialGameState, setInitialGameState] = useState(GameState.NOT_STARTED);
  const [initialGameTranscriptRecords, setInitialGameTranscriptRecords] = useState<GameTranscriptRecord[]>([]);
  const [globalGameStats, setGlobalGameStats] = useState<GlobalGameStats>();
  const [errorScreenType, setErrorScreenType] = useState<ErrorScreenType>();
  const [showSpoilerConfirmation, setShowSpoilerConfirmation] = useState(false);
  const gameSessionId = sessionId ?? "";

  if (isPageLoading) {
    getUserInfo().then((userInfo: UserInfo) => {
      getSessionResult(gameSessionId).then(wordDetails => {
        setUserInfo(userInfo);
        setGameId(wordDetails.gameId);
        setSecretWord(wordDetails.secretWord);
        setTheme(wordDetails.theme);
        setDailyWordNumber(wordDetails.dailyWordNumber);
        setPlayerUserId(wordDetails.playerUserId);
        setInitialGameTranscriptRecords(wordDetails.transcriptRecords);
        const askedQuestions: AskedQuestion[] = wordDetails.transcriptRecords.filter(record => record.getType() === GameTranscriptRecordType.AskedQuestion).map(record => record.getAskedQuestion());
        if (askedQuestions.length >= 10 && askedQuestions[askedQuestions.length - 1].getNormalizedQuestionAnswerPair().getAnswer() !== AnswerResult.CORRECT_GUESS) {
          setInitialGameState(GameState.FAILED);
        } else {
          setInitialGameState(GameState.COMPLETED);
        }
        setGlobalGameStats(wordDetails.globalGameStats);

        setShowSpoilerConfirmation(!alreadyConfirmedSpoiler(wordDetails.gameId));
        setIsPageLoading(false);
      }).catch((error) => {
        if (error.code === "functions/not-found") {
          setErrorScreenType(ErrorScreenType.NOT_FOUND);
        } else {
          setErrorScreenType(ErrorScreenType.GENERIC_ERROR);
        }
      });
    });
  };

  const userConfirmedSpoiler = () => {
    setConfirmedSpoiler(gameId);
    setShowSpoilerConfirmation(false);
  }

  return (
    <>
      {isPageLoading && errorScreenType === undefined && <PageLoadingScreen />}
      {!isPageLoading && showSpoilerConfirmation &&
        <SpoilerConfirmation onClickCallback={userConfirmedSpoiler} dailyWordNumber={dailyWordNumber} gameId={gameId} />
      }
      {
        !isPageLoading && errorScreenType === undefined && !showSpoilerConfirmation &&
        <GlobalStateProvider
          acceptedVariants={[]}
          acceptedThemeWords={[]}
          faq={[]}
          gameId={gameId}
          globalGameStats={globalGameStats!}
          secretWord={secretWord}
          theme={theme}
          dailyWordNumber={dailyWordNumber}
          playerUserId={playerUserId}
          initialGameState={initialGameState}
          initialGameTranscriptRecords={initialGameTranscriptRecords}
          initialHints={[]}
          initialGameSessionId={gameSessionId}
          initialUserInfo={userInfo!}
          initialCurrentWinStreak={0}
        >
          <Header />
          <Container fluid="sm">
            <div className="mt-4">
              <FinishedSummaryCard enableSettings={false} />
            </div>
            <div className="mt-4">
              <GameTranscript reverse={false} showExplanations={true} canMarkAsMisleading={userInfo!.getUserId() === playerUserId} />
            </div>
            <div className="mt-4 mb-4">
              <GameStats />
            </div>
          </Container>
          <Footer />
        </GlobalStateProvider>
      }
      {errorScreenType !== undefined && <ErrorScreen type={errorScreenType} />}
    </>
  );
}

export default SessionPage;
