import { useContext } from "react";
import { LlmModelName } from "../../common/global-enums";
import { GlobalStateContext, IGlobalStateContextValue } from "../../context/GlobalStateContext";
import { Card, CardLink, Image } from "react-bootstrap";
import { modelToGeneralNameMap, modelToIconMap, modelToSpecificNameMap } from "../../common/llm-player-display-utils";

type Props = {
    llmModelName: LlmModelName,
};

// Displays the game results for a single LLM.
function LlmPlayerCard(props: Props) {
    const {
        globalGameStats
    } = useContext<IGlobalStateContextValue | undefined>(GlobalStateContext)!;

    const getStylizedResultCount = (resultCount: number): string => {
        if (resultCount > 0) {
            return String(resultCount) + " questions";
        }
        return "Incomplete";
    };

    return (
        <Card className="mb-2">
            <Card.Header>
                <Card.Title className="d-flex align-items-center">
                    <Image className="llm-icon" src={process.env.REACT_APP_PUBLIC_URL + modelToIconMap.get(props.llmModelName)!}></Image>
                    <span className="ms-2">{modelToGeneralNameMap.get(props.llmModelName)}</span>
                </Card.Title>
                <Card.Text><em>{modelToSpecificNameMap.get(props.llmModelName)}</em></Card.Text>
            </Card.Header>
            <Card.Body>
                <ul>
                    {globalGameStats!.getLlmGameStats().getGameResults().get(props.llmModelName)?.map(
                        llmPlayerResult =>
                            <li key={llmPlayerResult.gameSessionId}>
                                <CardLink>
                                    <a target="_blank" href={process.env.REACT_APP_PUBLIC_URL + "session/" + llmPlayerResult.gameSessionId}>{getStylizedResultCount(llmPlayerResult.resultCount)}</a>
                                </CardLink>
                            </li>
                    )}
                </ul>
            </Card.Body>
        </Card>
    );

}

export default LlmPlayerCard;