import { Button, Image } from 'react-bootstrap';
import '../../styles/RobotContainer.scss';
import Header from './Header';
import { getFormattedDateForGameId } from '../../common/display-utils';

type Props = {
    onClickCallback: () => void;
    dailyWordNumber: number;
    gameId: string;
};

// Spoiler confirmation screen.
function SpoilerConfirmation(props: Props) {
    const formattedDate = getFormattedDateForGameId(props.gameId);

    return (
        <>
            <Header onlyShowBrand={true} />

            <div className="position-absolute top-50 start-50 translate-middle">
                <div className="robot-container">
                    <div className="image-container">
                        <Image src={process.env.REACT_APP_PUBLIC_URL + 'happy-robot.webp'} className="w-100" />
                    </div>
                    <div className="speech-bubble">
                        <div className="container-fade-in-down">
                            <p>Spoiler Warning!</p>
                            {props.gameId.startsWith("dailyword:") &&
                                <p>This contains spoilers for <strong>Quest #{props.dailyWordNumber}</strong> ({formattedDate}). You can play today's quest <a href="https://quest10ns.com">here</a>.</p>}
                            {!props.gameId.startsWith("dailyword:") &&
                                <p>This contains spoilers for <strong>Quest {props.gameId}</strong>. You can play the quest <a href={"https://quest10ns.com/game/" + props.gameId}>here</a>.</p>}
                            <Button variant="primary" className="w-100 mb-2" size="sm" onClick={props.onClickCallback}>
                                Continue
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SpoilerConfirmation;