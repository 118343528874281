import { Card } from "react-bootstrap";
import { GameWithUserResult } from "../../common/game-with-user-result";
import UserGameResultButton from "../shared/UserGameResultButton";

type Props = {
    game: GameWithUserResult
};

function GameCard(props: Props) {
    const game = props.game;
    return <Card>
        <Card.Body>
            <Card.Title>
                Theme: {game.getGame().getTheme()}
            </Card.Title>
            <Card.Text>
                Number of Plays: {game.getGame().getHumanGameCount()}<br />
                Average # AI questions to win: {game.getGame().getAverageLlmQuestionsToWin() > 0 ? game.getGame().getAverageLlmQuestionsToWin() : "-"}<br />
            </Card.Text>
        </Card.Body>
        <Card.Footer className="text-center">
            <UserGameResultButton gameWithUserResult={game} stretchedLink={true} />
        </Card.Footer>
    </Card>
}


export default GameCard;