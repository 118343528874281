import { useContext } from "react";
import "../../styles/GameStatsBody.scss";

import { Col, Container, OverlayTrigger, Popover, Row, Table } from "react-bootstrap";
import { QuestionCircle } from "react-bootstrap-icons";
import { GlobalStateContext, IGlobalStateContextValue } from "../../context/GlobalStateContext";
import { LlmModelName } from "../../common/global-enums";
import LlmPlayerCard from "./LlmPlayerCard";
import GameResultDistribution from "./GameResultDistribution";

function GameStatsBody() {
    const {
        globalGameStats
    } = useContext<IGlobalStateContextValue | undefined>(GlobalStateContext)!;

    const scorePopover = (
        <Popover id="score-popover">
            <Popover.Header>Quest10n Score Definition</Popover.Header>
            <Popover.Body>
                <p>The score is calculated using:</p>
                <p>
                    (11 - [Avg # of questions to win]) * [Win rate]
                </p>
            </Popover.Body>
        </Popover>
    );

    let llmModelNamesForGame: string[] = [];
    for (const llmModelName of Object.values<string>(LlmModelName)) {
        if (globalGameStats?.getLlmGameStats().getGameResults().has(llmModelName as LlmModelName)) {
            llmModelNamesForGame.push(llmModelName);
        }
    }

    return <Container>
        <Row>
            <Col>
                <Row className="mt-1">
                    <div className="text-center fs-5">Breakdown</div>
                </Row>
                <Row className="mt-2">
                    <Table bordered striped>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Humans</th>
                                <th>AI</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td># Sessions Played</td>
                                <td>{globalGameStats!.getHumanGameStats().getTotalGames()}</td>
                                <td>{globalGameStats!.getLlmGameStats().getTotalGames()}</td>
                            </tr>
                            <tr>
                                <td>Win rate</td>
                                <td>{globalGameStats!.getHumanGameStats().getWinRate()}%</td>
                                <td>{globalGameStats!.getLlmGameStats().getWinRate()}%</td>
                            </tr>
                            <tr>
                                <td>Avg # of questions to win</td>
                                <td>{globalGameStats!.getHumanGameStats().getAverageQuestionsToWin()}</td>
                                <td>{globalGameStats!.getLlmGameStats().getAverageQuestionsToWin()}</td>
                            </tr>
                            <tr>
                                <td><strong>Quest10n Score</strong> <OverlayTrigger trigger="click" placement="top" rootClose overlay={scorePopover}><QuestionCircle className="combined-score-question" /></OverlayTrigger></td>
                                <td><strong>{globalGameStats!.getHumanGameStats().getScore()}</strong></td>
                                <td><strong>{globalGameStats!.getLlmGameStats().getScore()}</strong></td>
                            </tr>
                        </tbody>
                    </Table>
                </Row>
            </Col>
        </Row>
        <Row>
            <Col>
                <Row>
                    <div className="text-center fs-5">Human Guess Distribution</div>
                </Row>
                <Row>
                    <GameResultDistribution humanGameStats={globalGameStats!.getHumanGameStats()} />
                </Row>
            </Col>
        </Row>
        <Row>
            <Col>
                <Row>
                    <div className="text-center fs-5">AI Players</div>
                </Row>
                <Row className="mt-2" xs={1} md={2}>
                    {llmModelNamesForGame.map((llmModelName, index) => (
                        <Col key={index}>
                            <LlmPlayerCard llmModelName={llmModelName as LlmModelName} />
                        </Col>
                    ))}
                </Row>
            </Col>
        </Row>
        <Row>
            <div className="text-center"><em>Scores updated every minute</em></div>
        </Row>
    </Container>
}

export default GameStatsBody;