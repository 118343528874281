import '../../styles/AnswerIconContainer.scss';

import { CheckCircle, QuestionCircle, Trophy, XCircle } from 'react-bootstrap-icons';
import { AnswerResult } from '../../common/global-enums';

type Props = {
    answerResult: AnswerResult,
    includeText: boolean
};

// Displays the icon container for an answer result.
function AnswerIconContainer(props: Props) {
    const colorName: string =
        props.answerResult === AnswerResult.NO ? "bg-danger" :
            props.answerResult === AnswerResult.YES ? "bg-success" :
                props.answerResult === AnswerResult.MAYBE ? "bg-secondary" :
                    "bg-warning";

    return (
        <div className={"w-100 h-100 rounded " + colorName}>
            <div>
                {props.answerResult === AnswerResult.NO && <XCircle className="answer-icon-image" color="white" />}
                {props.answerResult === AnswerResult.YES && <CheckCircle className="answer-icon-image" color="white" />}
                {props.answerResult === AnswerResult.MAYBE && <QuestionCircle className="answer-icon-image" color="white" />}
                {props.answerResult === AnswerResult.CORRECT_GUESS && <Trophy className="answer-icon-image" color="white" />}
            </div>

            {props.includeText &&
                <div className="answer-text text-white text-center">
                    {props.answerResult === AnswerResult.NO ? "NO" : null}
                    {props.answerResult === AnswerResult.YES ? "YES" : null}
                    {props.answerResult === AnswerResult.MAYBE ? "MAYBE" : null}
                    {props.answerResult === AnswerResult.CORRECT_GUESS ? "GOAL" : null}
                </div>
            }
        </div>
    );
}

export default AnswerIconContainer;