import { ColumnDef, flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { DateTime } from "luxon";
import { Col, Row, Table } from "react-bootstrap";
import { SortDown, SortUp } from "react-bootstrap-icons";
import { GameWithUserResult } from "../../common/game-with-user-result";
import UserGameResultButton from "../shared/UserGameResultButton";

type Props = {
    games: GameWithUserResult[]
};

const columns: ColumnDef<GameWithUserResult>[] = [
    {
        id: "dailyWordNumber",
        header: 'Daily Quest #',
        accessorKey: 'game.dailyWordNumber',
        sortingFn: 'basic',
        sortDescFirst: true,
        cell: props =>
            <div ><strong className="text-primary">[{props.row.original.getGame().getDailyWordNumber()}]</strong> {props.row.original.getGame().getDailyWordDate()!.toLocaleString(DateTime.DATE_MED)}</div>
    },
    {
        header: 'Theme',
        accessorKey: 'game.theme',
        sortingFn: 'alphanumeric',
    },
    {
        header: 'Your Result',
        accessorFn: row => row.getUserGameResult(),
        enableSorting: false,
        cell: props => <UserGameResultButton gameWithUserResult={props.row.original} stretchedLink={false} />
    },
];

function DailyGamesTable(props: Props) {
    const table = useReactTable({
        data: props.games,
        columns: columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        enableSortingRemoval: false,
        initialState: {
            sorting: [
                {
                    id: 'dailyWordNumber',
                    desc: true,
                }
            ]
        }
    });

    return (
        <Row className="justify-content-sm-center mt-2">
            <Col>
                <Table>
                    <thead>
                        {table.getHeaderGroups().map(headerGroup => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map(header => (
                                    <th key={header.id} colSpan={header.colSpan}>
                                        {header.isPlaceholder
                                            ? null
                                            : <div
                                                role={header.column.getCanSort() ? "button" : ""}
                                                onClick={header.column.getToggleSortingHandler()}
                                                title={
                                                    header.column.getCanSort()
                                                        ? header.column.getNextSortingOrder() === 'asc'
                                                            ? 'Sort ascending'
                                                            : header.column.getNextSortingOrder() === 'desc'
                                                                ? 'Sort descending'
                                                                : 'Clear sort'
                                                        : undefined
                                                }
                                            >
                                                {flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )}
                                                {{
                                                    asc: <SortUp className="ms-1" />,
                                                    desc: <SortDown className="ms-1" />,
                                                }[header.column.getIsSorted() as string] ?? null}
                                            </div>}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {table.getRowModel().rows.map(row => (
                            <tr key={row.id}>
                                {row.getVisibleCells().map(cell => (
                                    <td key={cell.id}>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </Col>
        </Row>
    );
}

export default DailyGamesTable;