import './styles/animations.scss';

import React, { useContext, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import { GameState } from './common/global-enums';
import FinishedScreen from './components/finished/FinishedScreen';
import OngoingScreen from './components/ongoing/OngoingScreen';
import { IGlobalStateContextValue, GlobalStateContext } from './context/GlobalStateContext';
import { retrieveGameTranscriptRecords } from './common/ongoing-session-storage';
import { GameTranscriptRecordType } from './common/game-transcript-record';
import { getMessageResponseMessage } from './components/ongoing/HintQuestionContainer';

const Main = (): React.ReactElement => {
    const {
        gameState,
        setGameState,
        gameId,
        secretWord,
        hints,
        showHintsAfterNumQuestions,
        setGameTranscriptRecords,
        setQuestionResponseMessage,
    } = useContext<IGlobalStateContextValue | undefined>(GlobalStateContext)!;

    const [transitionInFinishedScreen, setTransitionInFinishedScreen] = useState(false);

    // Resume an ongoing game session if it exists.
    useEffect(() => {
        const storedGameTranscriptRecords = retrieveGameTranscriptRecords(gameId, secretWord);
        if (storedGameTranscriptRecords.length > 0) {
            const numAskedQuestions = storedGameTranscriptRecords.filter(record => record.getType() === GameTranscriptRecordType.AskedQuestion).length;
            const isTimeForHint = numAskedQuestions % showHintsAfterNumQuestions === 0;
            const lastTranscriptRecordIsHintResponse =
                storedGameTranscriptRecords[storedGameTranscriptRecords.length - 1].getType() === GameTranscriptRecordType.HintResponse;
            if (hints.length > 0 && isTimeForHint && !lastTranscriptRecordIsHintResponse) {
                setGameState(GameState.AWAITING_USER_HINT_RESPONSE);
            } else {
                if (lastTranscriptRecordIsHintResponse) {
                    setQuestionResponseMessage(getMessageResponseMessage(storedGameTranscriptRecords[storedGameTranscriptRecords.length - 1].getHintResponse()));
                }
                setGameState(GameState.AWAITING_USER_QUESTION);
            }
            setGameTranscriptRecords(storedGameTranscriptRecords);
        }
    }, []);


    const updateGameStateAfterOngoingScreenFadeOut = () => {
        if (gameState === GameState.TRANSITIONING_TO_COMPLETED) {
            setGameState(GameState.COMPLETED);
        } else if (gameState === GameState.TRANSITIONING_TO_FAILED) {
            setGameState(GameState.FAILED);
        }
        setTransitionInFinishedScreen(true);
    };

    return (
        <Container fluid="sm">
            {(gameState === GameState.COMPLETED || gameState === GameState.FAILED) &&
                <div className={transitionInFinishedScreen ? "container-fade-in" : ""}>
                    <FinishedScreen />
                </div>
            }
            {(gameState !== GameState.COMPLETED && gameState !== GameState.FAILED) &&
                <div className={gameState === GameState.TRANSITIONING_TO_COMPLETED || gameState === GameState.TRANSITIONING_TO_FAILED ? "container-fade-out" : ""}
                    onAnimationEnd={updateGameStateAfterOngoingScreenFadeOut}>
                    <OngoingScreen />
                </div>
            }
        </Container>
    );
}

export default Main;
