import { Col, Row } from "react-bootstrap";
import { GameWithUserResult } from "../../common/game-with-user-result";
import GameCard from "./GameCard";

type Props = {
    games: GameWithUserResult[]
};

function RecentGamesSection(props: Props) {
    return (
        <>
            <hr />
            <Row xs={1} md={2} className="g-4">
                {Array.from(props.games).map((game, idx) => (
                    <Col key={idx}>
                        <GameCard game={game} />
                    </Col>
                ))}
            </Row>
        </>
    );
}

export default RecentGamesSection;