import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import { getFirebaseApp } from './firebase-app';
import { GameTranscriptRecord, GameTranscriptRecordDto } from "../common/game-transcript-record";

/* Represents what's sent in the request to the Cloud function. */
interface FunctionRequest {
    transcriptRecords: GameTranscriptRecordDto[];
    secretWord: string;
    gameId: string;
    userId: string;
}

/* Represents what's returned in the response from the Cloud function. */
interface FunctionResponse {
    gameSessionId: string;
    currentWinStreak: number;
}

/** What callers pass in to publishGameSession(). */
export type PublishGameSessionRequest = {
    transcriptRecords: GameTranscriptRecord[];
    secretWord: string;
    gameId: string;
    userId: string;
}

export type PublishGameSessionResponse = {
    gameSessionId: string;
    currentWinStreak: number;
}

export function publishGameSession(request: PublishGameSessionRequest): Promise<PublishGameSessionResponse> {
    const functions = getFunctions(getFirebaseApp());
    // Connect to local functions when running locally.
    if (process.env.NODE_ENV === "development") {
        connectFunctionsEmulator(functions, "127.0.0.1", 5001);
    }
    const publishGameSession = httpsCallable<FunctionRequest, FunctionResponse>(functions, 'publishGameSession');
    return publishGameSession(
        {
            transcriptRecords: request.transcriptRecords.map(record => record.toDto()),
            secretWord: request.secretWord,
            gameId: request.gameId,
            userId: request.userId,
        }
    ).then((result) => {
        return {
            gameSessionId: result.data.gameSessionId,
            currentWinStreak: result.data.currentWinStreak
        };
    }).catch((error) => {
        console.log("Error while publishing game session: " + error);
        return {
            gameSessionId: "",
            currentWinStreak: 0
        };
    });
}