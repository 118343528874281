import { useState } from "react";
import { UserInfo, getUserInfo } from "./common/user-info";
import ErrorScreen, { ErrorScreenType } from "./components/shared/ErrorScreen";
import PageLoadingScreen from "./components/PageLoadingScreen";
import Header from "./components/shared/Header";
import { Card, Col, Container, Row } from "react-bootstrap";
import Footer from "./components/shared/Footer";
import GlobalStateProvider from "./context/GlobalStateContext";
import { GameState } from "./common/global-enums";
import { getDailyGames } from "./firebase/get-daily-games-wrapper";
import { GameWithUserResult } from "./common/game-with-user-result";
import DailyGamesTable from "./components/daily/DailyGamesTable";

const DailyGamesPage = (): React.ReactElement => {
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [games, setGames] = useState<GameWithUserResult[]>();
    const [initialUserInfo, setInitialUserInfo] = useState<UserInfo>(UserInfo.createEmpty());
    const [errorScreenType, setErrorScreenType] = useState<ErrorScreenType>();

    if (isPageLoading) {
        getUserInfo().then((userInfo: UserInfo) => {
            getDailyGames(userInfo.getUserId()).then((games: GameWithUserResult[]) => {
                setGames(games);
                setIsPageLoading(false);
                setInitialUserInfo(userInfo);
            }).catch((error) => {
                if (error.code === "functions/not-found") {
                    setErrorScreenType(ErrorScreenType.NOT_FOUND);
                } else {
                    setErrorScreenType(ErrorScreenType.GENERIC_ERROR);
                }
            });
        }).catch((error) => {
            if (error.code === "functions/not-found") {
                setErrorScreenType(ErrorScreenType.NOT_FOUND);
            } else {
                setErrorScreenType(ErrorScreenType.GENERIC_ERROR);
            }
        });

    }

    return (
        <>
            {isPageLoading && errorScreenType === undefined && <PageLoadingScreen />}
            {
                !isPageLoading && errorScreenType === undefined &&
                <GlobalStateProvider
                    acceptedVariants={[]}
                    acceptedThemeWords={[]}
                    faq={[]}
                    globalGameStats={undefined}
                    gameId={""}
                    secretWord={""}
                    theme={""}
                    dailyWordNumber={0}
                    playerUserId={""}
                    initialGameState={GameState.NOT_STARTED}
                    initialGameTranscriptRecords={[]}
                    initialHints={[]}
                    initialGameSessionId={""}
                    initialUserInfo={initialUserInfo}
                    initialCurrentWinStreak={0}
                >
                    <Header />
                    <Container fluid="sm">
                        <div className="mt-4">
                            <Row className="justify-content-sm-center">
                                <Col lg={{ span: 10 }} xl={{ span: 9 }} xxl={{ span: 8 }}>
                                    <Card>
                                        <Card.Header className="text-center">
                                            <h4 className="text-primary">All Daily Quests</h4>
                                        </Card.Header>
                                        <Card.Body>
                                            <DailyGamesTable games={games!} />
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                    <Footer />
                </GlobalStateProvider>
            }
            {errorScreenType !== undefined && <ErrorScreen type={errorScreenType} />}

        </>

    );
}

export default DailyGamesPage;