import { ColumnDef, flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { DateTime } from "luxon";
import { UserStats } from "../../common/user-stats";
import { Game } from "../../common/game";
import { Button, Col, Row, Table } from "react-bootstrap";
import { SortDown, SortUp } from "react-bootstrap-icons";

type Props = {
    userStats: UserStats
};

const columns: ColumnDef<Game>[] = [
    {
        header: 'Secret Word',
        accessorKey: 'secretWord',
        sortingFn: 'alphanumeric'
    },
    {
        header: 'Theme',
        accessorKey: 'theme',
        sortingFn: 'alphanumeric',
    },
    {
        header: '# Times Played',
        accessorKey: 'humanGameCount',
        sortingFn: 'basic',
        sortDescFirst: false,
    },
    {
        header: 'Created',
        accessorKey: 'timeCreated',
        sortingFn: (rowA, rowB, _columnId) => {
            if (rowA.original.getTimeCreated() < rowB.original.getTimeCreated()) {
                return -1;
            }
            if (rowA.original.getTimeCreated() > rowB.original.getTimeCreated()) {
                return 1;
            }
            return 0;
        },
        cell: props => (props.getValue() as DateTime).toLocaleString(DateTime.DATETIME_MED),
        sortDescFirst: false,
    },
    {
        header: 'Links',
        accessorKey: 'gameId',
        enableSorting: false,
        cell: props =>
            <span>
                <a href={process.env.REACT_APP_PUBLIC_URL + "game/" + props.getValue()} target="_blank">Game</a> | <a href={process.env.REACT_APP_PUBLIC_URL + "gameResult/" + props.getValue()} target="_blank">Results</a>
            </span>
    },

];

function UserCreatedGames(props: Props) {
    const table = useReactTable({
        data: props.userStats.getUserCreatedGames(),
        columns: columns,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        enableSortingRemoval: false,
        initialState: {
            sorting: [
                {
                    id: 'secretWord',
                    desc: false,
                }
            ]
        }
    });

    return (
        <Row className="justify-content-sm-center mt-2">
            <Col>
                <Table>
                    <thead>
                        {table.getHeaderGroups().map(headerGroup => (
                            <tr key={headerGroup.id}>
                                {headerGroup.headers.map(header => (
                                    <th key={header.id} colSpan={header.colSpan}>
                                        {header.isPlaceholder
                                            ? null
                                            : <div
                                                role={header.column.getCanSort() ? "button" : ""}
                                                onClick={header.column.getToggleSortingHandler()}
                                                title={
                                                    header.column.getCanSort()
                                                        ? header.column.getNextSortingOrder() === 'asc'
                                                            ? 'Sort ascending'
                                                            : header.column.getNextSortingOrder() === 'desc'
                                                                ? 'Sort descending'
                                                                : 'Clear sort'
                                                        : undefined
                                                }
                                            >
                                                {flexRender(
                                                    header.column.columnDef.header,
                                                    header.getContext()
                                                )}
                                                {{
                                                    asc: <SortUp className="ms-1" />,
                                                    desc: <SortDown className="ms-1" />,
                                                }[header.column.getIsSorted() as string] ?? null}
                                            </div>}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {table.getRowModel().rows.map(row => (
                            <tr key={row.id}>
                                {row.getVisibleCells().map(cell => (
                                    <td key={cell.id}>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <Button variant="primary" href={process.env.REACT_APP_PUBLIC_URL + "create"} target="_blank">Create New Quest</Button>
            </Col>
        </Row>
    );

    return <></>;
}

export default UserCreatedGames;