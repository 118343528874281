import '../../styles/HintTranscriptRow.scss';

import { HintResponse } from "../../common/hint-response";
import { Badge, Col, Row } from 'react-bootstrap';

type Props = {
    // The HintResponse to display.
    hintResponse: HintResponse;
};

// Displays a row in the transcript for a HintResponse.
function HintTranscriptRow(props: Props) {
    return <>
        <td colSpan={3} className="bg-light">
            <Row>
                <Col className="text-center">
                    {props.hintResponse.getHint().getQuestion()}
                </Col>
            </Row>
            <Row className="mt-1">
                <Col className="text-center">
                    {props.hintResponse.getHint().getChoices().map((choice, index) => (
                        <span key={index} className={props.hintResponse.getUserResponse() === choice ? "transcript-hint-user-selected" : ""}>
                            <Badge
                                bg={props.hintResponse.getHint().getAnswer() === choice ?
                                    "success" : "none"}
                                className="mx-1 transcript-hint-default-choice">
                                {choice}
                            </Badge>
                        </span>

                    ))}
                </Col>
            </Row>
            {props.hintResponse.getUserResponseIndex() === props.hintResponse.getHint().getAnswerIndex() &&
                <Row className="mt-1">
                    <Col className="text-center">
                        <em>Bonus hint:</em> {props.hintResponse.getHint().getBonusInfo()}
                    </Col>
                </Row>
            }
        </td>
    </>;
}

export default HintTranscriptRow;