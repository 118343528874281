import { useContext } from 'react';
import '../../styles/RobotContainer.scss';
import '../../styles/animations.scss';

import Image from 'react-bootstrap/Image';
import { GlobalStateContext, IGlobalStateContextValue } from '../../context/GlobalStateContext';
import { GameState } from '../../common/global-enums';
import Plural from '../shared/Plural';
import ShareButton from './ShareButton';
import { Button } from 'react-bootstrap';

// Displays the robot with the speech bubble when the game is finished.
function FinishedRobotContainer() {
    const {
        gameId,
        gameState,
        numQuestionsAsked,
        secretWord,
        currentWinStreak,
        globalGameStats,
    } = useContext<IGlobalStateContextValue | undefined>(GlobalStateContext)!;
    const didUserFail = gameState === GameState.FAILED;

    const openGameProfile = () => {
        window.open(process.env.REACT_APP_PUBLIC_URL + "profile", "_blank");
    };

    const openGameResults = () => {
        window.open(process.env.REACT_APP_PUBLIC_URL + "gameResult/" + gameId, "_blank");
    };

    return (
        <div className="robot-container">
            <div className="image-container">
                <Image src={process.env.REACT_APP_PUBLIC_URL + 'finished-robot.webp'} className="w-100" />
            </div>
            <div className="speech-bubble">
                {didUserFail &&
                    <div className="container-fade-in-down">
                        <p>Nice try! The word was <code>{secretWord}</code>.</p>
                    </div>}
                {!didUserFail &&
                    <div className="container-fade-in-down">
                        <p>
                            Quest complete! You guessed the word <code>{secretWord}</code> in <strong>{numQuestionsAsked} <Plural num={numQuestionsAsked} singularText="question" pluralText="questions"></Plural></strong> (AI average: {globalGameStats!.getLlmGameStats().getAverageQuestionsToWin()}).
                        </p>
                        {currentWinStreak > 0 && <p>You're on a <strong>{currentWinStreak} game</strong> win streak! The next quest will be available at midnight.</p>}
                    </div>}

                <div className="mt-2">
                    <ShareButton />
                    <Button variant="primary" className="w-100 mb-2" onClick={openGameResults}>View Full Game Results</Button>
                    <Button variant="primary" className="w-100" onClick={openGameProfile}>View Your Stats</Button>
                </div>
            </div>
        </div>
    )
}

export default FinishedRobotContainer;