import { useContext } from "react";
import { GlobalStateContext, IGlobalStateContextValue } from "../../context/GlobalStateContext";
import { Col, Row, Table } from "react-bootstrap";
import { TOP_LEVEL_COMPONENT_COL_LG, TOP_LEVEL_COMPONENT_COL_MD, TOP_LEVEL_COMPONENT_COL_XL, TOP_LEVEL_COMPONENT_COL_XXL } from "../../common/display-utils";
import QuestionTranscriptRow from "../transcript/QuestionTranscriptRow";

type Props = {
    enableSpoilerFreeMode?: boolean;
};

// Displays the top questions that were asked for a game.
function TopAskedQuestions(props: Props) {
    const {
        faq,
    } = useContext<IGlobalStateContextValue | undefined>(GlobalStateContext)!;

    return <Row className="justify-content-sm-center">
        <Col md={TOP_LEVEL_COMPONENT_COL_MD} lg={TOP_LEVEL_COMPONENT_COL_LG} xl={TOP_LEVEL_COMPONENT_COL_XL} xxl={TOP_LEVEL_COMPONENT_COL_XXL}>
            <div className="border border-2 rounded">
                <Table style={{ margin: "0" }} striped>
                    <thead>
                        <tr>
                            <th colSpan={3} className={(props.enableSpoilerFreeMode ? "bg-dark" : "bg-primary") + " text-center text-white"}>Top 20 Asked Questions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!props.enableSpoilerFreeMode && <>
                            {faq.map((faqRecord, index) => (
                                <tr key={index}>
                                    <QuestionTranscriptRow
                                        askedQuestion={faqRecord}
                                        index={index}
                                        reverse={false}
                                        showExplanation={true}
                                        canMarkAsMisleading={false} />
                                </tr>
                            ))}
                        </>}
                    </tbody>
                </Table>
            </div>
        </Col>
    </Row>;
}

export default TopAskedQuestions;