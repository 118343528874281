import { Hint, HintDto } from '../common/hint';

// Contains a hint and the user's response.
export class HintResponse {
    private hint: Hint;
    private userResponseIndex: number;

    constructor(hint: Hint, userResponseIndex: number) {
        this.hint = hint;
        this.userResponseIndex = userResponseIndex;
    }

    public static fromDto(dto: HintResponseDto): HintResponse {
        return new HintResponse(Hint.fromDto(dto.hint), dto.userResponseIndex);
    }

    public getHint(): Hint {
        return this.hint;
    }

    public getUserResponseIndex(): number {
        return this.userResponseIndex;
    }

    public getUserResponse(): string {
        return this.hint.getChoices()[this.userResponseIndex];
    }

    public toDto(): HintResponseDto {
        return { hint: this.hint.toDto(), userResponseIndex: this.userResponseIndex };
    }
}

// Data transfer object version of HintResponse. 
export type HintResponseDto = {
    hint: HintDto;
    userResponseIndex: number;
}