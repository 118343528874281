import { encode as base64Encode, decode as base64Decode } from 'base-64';
import { GameTranscriptRecord, GameTranscriptRecordDto } from "./game-transcript-record";

const gameIdStorageKeyPrefix = "gameId:";

type StorageRecord = {
    secretWord: string;
    gameTranscriptRecords: GameTranscriptRecordDto[];
};

export function storeGameTranscriptRecords(gameId: string, secretWord: string, gameTranscriptRecords: GameTranscriptRecord[]) {
    const record: StorageRecord = {
        secretWord: base64Encode(secretWord),
        gameTranscriptRecords: gameTranscriptRecords.map(record => record.toDto())
    };
    localStorage.setItem(gameIdStorageKeyPrefix + gameId, JSON.stringify(record));
}

export function retrieveGameTranscriptRecords(gameId: string, secretWord: string): GameTranscriptRecord[] {
    const storedRecordString = localStorage.getItem(gameIdStorageKeyPrefix + gameId);
    if (!storedRecordString) {
        return [];
    }
    let storedRecord: StorageRecord;
    try {
        storedRecord = JSON.parse(storedRecordString);
    } catch (error) {
        console.log("Error while retrieving storage record: " + error);
        localStorage.removeItem(gameIdStorageKeyPrefix + gameId);
        return [];
    }
    // Sanity check to make sure the secret word for the stored record matches what's returned from the server.
    if (base64Decode(storedRecord.secretWord) !== secretWord) {
        localStorage.removeItem(gameIdStorageKeyPrefix + gameId);
        return [];
    }
    return storedRecord.gameTranscriptRecords.map(recordDto => GameTranscriptRecord.fromDto(recordDto));
}

export function clearGame(gameId: string) {
    localStorage.removeItem(gameIdStorageKeyPrefix + gameId);
}