import { FirebaseApp, initializeApp } from "firebase/app";
import { Analytics, getAnalytics } from "firebase/analytics";
import { ReCaptchaEnterpriseProvider, initializeAppCheck } from "firebase/app-check";

let instance: FirebaseApp | undefined = undefined;
let analyticsInstance: Analytics | undefined = undefined;

export function getFirebaseApp(): FirebaseApp {
    if (instance !== undefined) {
        return instance;
    }
    const firebaseConfig = {
        apiKey: "AIzaSyB3PpmMqN54U6oe8INUGp9IFsKm3_IP1o0",
        authDomain: "quest10ns.com",
        projectId: "twentyq-f0a71",
        storageBucket: "twentyq-f0a71.appspot.com",
        messagingSenderId: "950576822366",
        appId: "1:950576822366:web:6c1acf89314b90481f9ccf",
        measurementId: "G-XBZW2B6LM3"
    };
    instance = initializeApp(firebaseConfig);
    analyticsInstance = getAnalytics(instance);
    if (process.env.NODE_ENV === "development") {
        (window as any).FIREBASE_APPCHECK_DEBUG_TOKEN = true;
    }
    initializeAppCheck(instance, {
        provider: new ReCaptchaEnterpriseProvider(process.env.REACT_APP_RECAPTCHA_SITE_KEY!),
        isTokenAutoRefreshEnabled: true
    });
    return instance;
}

export function getFirebaseAnalytics(): Analytics {
    return analyticsInstance!;
}