import { AnswerResult } from './global-enums';
import { NormalizedQuestionAnswerPair, NormalizedQuestionAnswerPairDto } from './normalized-question-answer-pair';
import { convertToKeyFormat } from './word-utils';


// Contains a user-asked question and its corresponding answer.
export class AskedQuestion {
    private rawQuestion: string;
    private normalizedQuestionAnswerPair: NormalizedQuestionAnswerPair;
    private markedAsMisleading: boolean;

    constructor(rawQuestion: string, normalizedQuestionAnswerPair: NormalizedQuestionAnswerPair, markedAsMisleading: boolean) {
        this.rawQuestion = rawQuestion.trim();
        this.normalizedQuestionAnswerPair = normalizedQuestionAnswerPair;
        this.markedAsMisleading = markedAsMisleading;
    }

    public static create(rawQuestion: string, answer: AnswerResult, explanation: string) {
        return new AskedQuestion(rawQuestion, new NormalizedQuestionAnswerPair(getQuestionKey(rawQuestion), answer, explanation), /* markedAsMisleading= */false);
    }

    public static fromDto(dto: AskedQuestionDto): AskedQuestion {
        return new AskedQuestion(dto.rawQuestion, NormalizedQuestionAnswerPair.fromDto(dto.normalizedQuestionAnswerPair), dto.markedAsMisleading);
    }

    public getRawQuestion(): string {
        return this.rawQuestion;
    }

    public getNormalizedQuestionAnswerPair(): NormalizedQuestionAnswerPair {
        return this.normalizedQuestionAnswerPair;
    }

    public getMarkedAsMisleading(): boolean {
        return this.markedAsMisleading;
    }

    public setMarkedAsMisleading(markedAsMisleading: boolean) {
        this.markedAsMisleading = markedAsMisleading;
    }

    public toDto(): AskedQuestionDto {
        return { rawQuestion: this.rawQuestion, normalizedQuestionAnswerPair: this.normalizedQuestionAnswerPair.toDto(), markedAsMisleading: this.markedAsMisleading };
    }
};

// Data transfer object version of AskedQuestion. 
export type AskedQuestionDto = {
    rawQuestion: string;
    normalizedQuestionAnswerPair: NormalizedQuestionAnswerPairDto;
    markedAsMisleading: boolean;
}

/**
 * Returns a normalized version of the question that can be reliably used as a key.
 * 
 * 1. Trims whitespace
 * 2. Converts everything to lowercase
 * 3. Removes punctuation.
 * 4. Converts spaces to underscores.
 * 
 * @param rawQuestion the non-normalized question that the user asked
 */
export function getQuestionKey(rawQuestion: string) {
    const formattedKey = convertToKeyFormat(rawQuestion);
    const numUnderscores = (formattedKey.match(/_/g) || []).length;
    // If it look like the user is directly guessing the word, prepend 
    // "is_it_" to the question key so it's the same as if the user asked "is it ...".
    if (numUnderscores <= 1) {
        return "is_it_" + formattedKey;
    }
    return formattedKey;
}