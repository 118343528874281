import { getAuth, GoogleAuthProvider, signInWithPopup, UserCredential, AuthError, linkWithPopup, AuthErrorCodes, signInWithCredential } from 'firebase/auth';
import { getFirebaseApp } from '../../firebase/firebase-app';
import GoogleSignInButton from './GoogleSignInButton';
import { UserInfo } from '../../common/user-info';
import { mergeUsers } from '../../firebase/merge-users-wrapper';

interface Props {
    currentUserInfo: UserInfo;

    // Callback to be invoked when the user successfully signs in.
    signInSuccessCallback(userId: string, email: string): void;

    // Callback to be invoked when the user had an error signing in.
    signInFailureCallback(errorCode: string): void;
}

const FirebaseAuthContainer = ({ currentUserInfo, signInSuccessCallback, signInFailureCallback }: Props) => {
    const googleProvider = new GoogleAuthProvider();
    const firebaseAuth = getAuth(getFirebaseApp());

    const handleGoogleSignInClick = () => {
        // If this is the first game the user is playing, create a new user account.
        if (!currentUserInfo.hasUser()) {
            signInWithPopup(firebaseAuth, googleProvider).then((userCred: UserCredential) => {
                signInSuccessCallback(userCred.user.uid, userCred.user.email!);
            }).catch((error: AuthError) => {
                console.log(error);
                signInFailureCallback(error.code);
            });
        }

        // If there's an anonymous user, upgrade the existing account to be authenticated.
        if (currentUserInfo.hasUser() && !currentUserInfo.isAuthenticated()) {
            const anonymousUser = firebaseAuth.currentUser!;
            linkWithPopup(anonymousUser, googleProvider).then((userCred: UserCredential) => {
                signInSuccessCallback(userCred.user.uid, userCred.user.email || "");
            }).catch((error: AuthError) => {
                // The user linked to an account that already exists. We need to handle the merge conflict
                // by updating all game sessions by the anonymous user to be linked to the existing account instead
                // and then deleting the anonymous user.
                if (error.code === AuthErrorCodes.CREDENTIAL_ALREADY_IN_USE) {
                    const credential = GoogleAuthProvider.credentialFromError(error);
                    if (!credential) {
                        signInFailureCallback(error.code);
                        return;
                    }
                    const anonymousUserId = anonymousUser.uid;
                    anonymousUser.delete().then(() => {
                        signInWithCredential(firebaseAuth, credential).then((userCred: UserCredential) => {
                            const mergeUsersPromise = mergeUsers({ anonymousUserId: anonymousUserId, existingAuthenticatedUserId: userCred.user.uid });
                            mergeUsersPromise.then(() => {
                                signInSuccessCallback(userCred.user.uid, userCred.user.email!);
                            });
                        });
                    });
                    return;
                }
                console.log(error);
                signInFailureCallback(error.code);
            });
        }

    };

    return (
        <div className="d-flex justify-content-center">
            <GoogleSignInButton onClick={handleGoogleSignInClick} />
        </div>
    );
}

export default FirebaseAuthContainer;