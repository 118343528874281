import QuestionForm from './QuestionForm';
import GameTranscript from '../transcript/GameTranscript';
import OngoingRobotContainer from './OngoingRobotContainer';
import OngoingIntroCard from './OngoingIntroCard';
import { useRef } from 'react';

// Handles the experience while the game is ongoing.
function OngoingScreen() {
    const queryInputRef = useRef<HTMLInputElement>(null);

    function focusOnQueryInputRef() {
        if (queryInputRef.current) {
            queryInputRef.current!.value = "";
            setTimeout(() => queryInputRef.current!.focus(), 50);
        }
    }

    return (
        <>
            <div className="mt-4">
                <OngoingIntroCard />
            </div>
            <div className="mt-4">
                <QuestionForm queryInputRef={queryInputRef} focusOnQueryInputRef={focusOnQueryInputRef} />
            </div>
            <div className="mt-3">
                <OngoingRobotContainer focusOnQueryInputRef={focusOnQueryInputRef} />
            </div>
            <div className="mt-3 mb-4">
                <GameTranscript reverse={true} showExplanations={false} canMarkAsMisleading={false} />
            </div>
        </>
    );
}

export default OngoingScreen;