import { LlmModelName } from "./global-enums";

export const modelToGeneralNameMap = new Map<LlmModelName, string>([
    [LlmModelName.GPT_3_5_TURBO, "ChatGPT"],
    [LlmModelName.GPT_4_O_MINI, "ChatGPT"],
    [LlmModelName.GEMINI_PRO_1_0, "Google Gemini"],
    [LlmModelName.GEMINI_1_5_FLASH, "Google Gemini"],
    [LlmModelName.LLAMA_3_8B_INSTRUCT, "Meta Llama"],
    [LlmModelName.CLAUDE_3_HAIKU, "Claude"],
]);
export const modelToSpecificNameMap = new Map<LlmModelName, string>([
    [LlmModelName.GPT_3_5_TURBO, "GPT 3.5 Turbo"],
    [LlmModelName.GPT_4_O_MINI, "GPT 4o Mini"],
    [LlmModelName.GEMINI_PRO_1_0, "Gemini Pro 1.0"],
    [LlmModelName.GEMINI_1_5_FLASH, "Gemini 1.5 Flash"],
    [LlmModelName.LLAMA_3_8B_INSTRUCT, "Llama 3 8b Instruct"],
    [LlmModelName.CLAUDE_3_HAIKU, "Claude 3 Haiku"],
]);
export const modelToIconMap = new Map<LlmModelName, string>([
    [LlmModelName.GPT_3_5_TURBO, "chatgpt-icon.png"],
    [LlmModelName.GPT_4_O_MINI, "chatgpt-icon.png"],
    [LlmModelName.GEMINI_PRO_1_0, "google-gemini-icon.png"],
    [LlmModelName.GEMINI_1_5_FLASH, "google-gemini-icon.png"],
    [LlmModelName.LLAMA_3_8B_INSTRUCT, "meta-icon.png"],
    [LlmModelName.CLAUDE_3_HAIKU, "claude-ai-icon.png"],
]);