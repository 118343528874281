import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import { getFirebaseApp } from './firebase-app';

/** What callers pass in to mergeUsers(). */
export type MergeUsersRequest = {
    anonymousUserId: string;
    existingAuthenticatedUserId: string;
}

export function mergeUsers(request: MergeUsersRequest): Promise<void> {
    const functions = getFunctions(getFirebaseApp());
    // Connect to local functions when running locally.
    if (process.env.NODE_ENV === "development") {
        connectFunctionsEmulator(functions, "127.0.0.1", 5001);
    }
    const mergeUsers = httpsCallable<MergeUsersRequest, null>(functions, 'mergeUsers');
    return mergeUsers(request).then((_result) => {
        return;
    }).catch((error) => {
        console.log("Error while merging users: " + error);
        return;
    });;
}