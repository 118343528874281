import '../../styles/animations.scss';

import { useContext } from "react";
import { GlobalStateContext, IGlobalStateContextValue } from "../../context/GlobalStateContext";
import { Button } from "react-bootstrap";
import { GameState } from "../../common/global-enums";
import { GameTranscriptRecord } from "../../common/game-transcript-record";
import { HintResponse } from "../../common/hint-response";
import { storeGameTranscriptRecords } from "../../common/ongoing-session-storage";

type Props = {
    focusOnQueryInputRef: () => void,
};

function HintQuestionContainer(props: Props) {
    const {
        numQuestionsAsked,
        showHintsAfterNumQuestions,
        hints,
        gameId,
        secretWord,
        gameTranscriptRecords,
        setGameTranscriptRecords,
        setGameState,
        setQuestionResponseMessage,
    } = useContext<IGlobalStateContextValue | undefined>(GlobalStateContext)!;

    const hintIndex = Math.floor((numQuestionsAsked / showHintsAfterNumQuestions)) - 1;
    if (hintIndex > hints.length) {
        console.log("Hint index of %d is greater than the hints available: %d", hintIndex, hints.length);
        return (<></>);
    }
    const hint = hints[hintIndex];

    const handleChoiceClick = (index: number) => {
        const hintResponse = new HintResponse(hint, index);
        const updatedGameTranscriptRecords = [...gameTranscriptRecords, GameTranscriptRecord.forHintResponse(hintResponse)];
        setGameTranscriptRecords(updatedGameTranscriptRecords);
        storeGameTranscriptRecords(gameId, secretWord, updatedGameTranscriptRecords);
        setQuestionResponseMessage(getMessageResponseMessage(hintResponse));
        setGameState(GameState.AWAITING_USER_QUESTION);
        props.focusOnQueryInputRef();
    };

    const choiceButtons = hint.getChoices().map((choice: string, index: number) => {
        return <Button variant="primary" className="w-100 mb-2" size="sm" key={index} onClick={() => handleChoiceClick(index)}>
            {choice}
        </Button>
    });


    return (
        <div className="container-fade-in-down">
            <span>Time to test your knowledge! {hint.getQuestion()}</span>
            <div className="mt-3">
                {choiceButtons}
            </div>
        </div>
    );
}

export function getMessageResponseMessage(hintResponse: HintResponse) {
    if (hintResponse.getUserResponseIndex() === hintResponse.getHint().getAnswerIndex()) {
        return "Correct! Bonus hint: " + hintResponse.getHint().getBonusInfo();
    }
    return "The correct answer is \"" + hintResponse.getHint().getAnswer() + "\".";
}

export default HintQuestionContainer;