import { Container, Row } from 'react-bootstrap';
import '../styles/PageLoadingScreen.scss';

// Loading screen when the page is first loading.
function PageLoadingScreen() {
    return (
        <>
            <Container fluid="sm">
                <Row className="justify-content-center align-items-center fill-remaining-height">
                    <div className="w-auto">
                        <div className="loader"></div>
                        <div className="loading-text">Loading</div>
                    </div>
                </Row>
            </Container>

        </>
    );
}

export default PageLoadingScreen;