import '../../styles/QuestionTranscriptRow.scss';

import { Badge, Button, Overlay, OverlayTrigger, Popover } from "react-bootstrap";
import { AskedQuestion } from "../../common/asked-question";
import AnswerIconContainer from "../shared/AnswerIconContainer";
import { useContext, useRef, useState } from "react";
import { GlobalStateContext, IGlobalStateContextValue } from "../../context/GlobalStateContext";
import { setMisleadingAnswer } from '../../firebase/set-misleading-answer-wrapper';
import { Flag } from 'react-bootstrap-icons';
import { FrequentlyAskedQuestion } from '../../common/frequently-asked-question';

type Props = {
    // The AskedQuestion or FrequentlyAskedQuestion to display.
    askedQuestion: AskedQuestion | FrequentlyAskedQuestion;
    // The index of the question.
    index: number;
    // Whether to display the latest questions first.
    reverse: boolean;
    // Whether to show the explanation.
    showExplanation: boolean;
    // Whether you can mark an answer as misleading.
    canMarkAsMisleading: boolean;
};

const alreadyShownStorageKey = "explanation_showcase_already_shown";

// Displays a row in the transcript for an AskedQuestion.
function QuestionTranscriptRow(props: Props) {
    const {
        numQuestionsAsked,
        faq,
        gameSessionId,
        userInfo,
    } = useContext<IGlobalStateContextValue | undefined>(GlobalStateContext)!;

    const [showExplanationShowcase, setShowExplanationShowcase] = useState(false);
    const [hasSeenExplanationShowcase, setHasSeenExplanationShowcase] = useState(localStorage.getItem(alreadyShownStorageKey) === "1");
    const [misleadingButtonDisabled, setMisleadingButtonDisabled] = useState(false);
    const [showMisleadingError, setShowMisleadingError] = useState(false);
    const hasExplanation = props.askedQuestion.getNormalizedQuestionAnswerPair().getExplanation().length > 0;

    const getMisleadingPercentage = (): number => {
        let faqRecord;
        if (props.askedQuestion instanceof AskedQuestion) {
            faqRecord = faq.find((faqRecord) => faqRecord.getNormalizedQuestionAnswerPair().getQuestionKey() === props.askedQuestion.getNormalizedQuestionAnswerPair().getQuestionKey());
        } else {
            faqRecord = (props.askedQuestion as FrequentlyAskedQuestion);
        }
        if (!faqRecord) {
            return 0;
        }
        if (faqRecord.getNumAsked() === 0 || faqRecord.getNumMarkedAsMisleading() === 0) {
            return 0;
        }
        return Math.min(Math.trunc((faqRecord.getNumMarkedAsMisleading() / faqRecord.getNumAsked()) * 100), 100);
    };

    const misleadingPercentage = getMisleadingPercentage();
    const showMisleadingFlag = () => {
        const misleadingPercentageThreshold = 25;
        if (props.askedQuestion instanceof AskedQuestion) {
            const faqRecord = faq.find((faqRecord) => faqRecord.getNormalizedQuestionAnswerPair().getQuestionKey() === props.askedQuestion.getNormalizedQuestionAnswerPair().getQuestionKey());
            return faqRecord && props.askedQuestion.getNormalizedQuestionAnswerPair().getAnswer() === faqRecord.getNormalizedQuestionAnswerPair().getAnswer() && misleadingPercentage >= misleadingPercentageThreshold;
        }
        return misleadingPercentage >= misleadingPercentageThreshold;
    }

    if (props.index === 0 && props.showExplanation && hasExplanation && !hasSeenExplanationShowcase) {
        setTimeout(() => {
            setShowExplanationShowcase(true);
        }, 500);
    }

    const explanationShowcaseTarget = useRef(null);

    const explanationPopover = (
        <Popover id="explanation-popover">
            <Popover.Header>AI Explanation</Popover.Header>
            <Popover.Body>
                <p>{props.askedQuestion.getNormalizedQuestionAnswerPair().getExplanation()}</p>
                {props.askedQuestion instanceof FrequentlyAskedQuestion &&
                    <p><em>Asked <strong>{props.askedQuestion.getNumAsked()}</strong> times</em></p>
                }
                {props.canMarkAsMisleading &&
                    <>
                        <Button size="sm" variant={(props.askedQuestion as AskedQuestion).getMarkedAsMisleading() ? "warning" : "light"} disabled={misleadingButtonDisabled} onClick={() => onMisleadingButtonClick((props.askedQuestion as AskedQuestion))}>
                            {(props.askedQuestion as AskedQuestion).getMarkedAsMisleading() ? "Unmark" : "Mark"} as misleading
                        </Button>
                        {showMisleadingError && <p className="text-danger"><em>Error, try again</em></p>}
                    </>
                }
            </Popover.Body>
        </Popover>
    );
    const misleadingPopover = (
        <Popover id="misleading-popover">
            <Popover.Body>
                {misleadingPercentage}% of users who asked this question marked this answer as misleading.
            </Popover.Body>
        </Popover>
    );

    const onMisleadingButtonClick = (askedQuestion: AskedQuestion) => {
        setMisleadingButtonDisabled(true);
        setMisleadingAnswer({
            gameSessionId,
            userId: userInfo.getUserId(),
            questionKey: askedQuestion.getNormalizedQuestionAnswerPair().getQuestionKey(),
            answerResult: askedQuestion.getNormalizedQuestionAnswerPair().getAnswer(),
            answerExplanation: askedQuestion.getNormalizedQuestionAnswerPair().getExplanation(),
            markAsMisleading: !(props.askedQuestion as AskedQuestion)
        }).then((wasSuccessful: boolean) => {
            setMisleadingButtonDisabled(false);
            setShowMisleadingError(false);
            if (wasSuccessful) {
                (props.askedQuestion as AskedQuestion).setMarkedAsMisleading(!(props.askedQuestion as AskedQuestion).getMarkedAsMisleading());
            } else {
                setShowMisleadingError(true);
            }
        });
    }

    const onExplanationShowcaseHide = () => {
        setShowExplanationShowcase(false);
        setHasSeenExplanationShowcase(true);
        localStorage.setItem(alreadyShownStorageKey, "1");
    }

    return <>
        <td style={{ width: "3rem" }} className="transcript-question-count align-middle">
            <Badge bg="none" className="transcript-question-badge">
                {props.reverse ? <span>{numQuestionsAsked - props.index}</span> : <span>{props.index + 1}</span>}
            </Badge>
        </td>
        <td className="align-middle">
            <div className="transcript-question-content text-center">
                {props.askedQuestion.getRawQuestion()}
            </div>
        </td>
        <td style={{ width: "4.5rem" }} className="align-middle">
            {showMisleadingFlag() &&
                <OverlayTrigger trigger="click" rootClose placement="top" overlay={misleadingPopover}>
                    <Flag className="text-warning align-middle" style={{ cursor: "pointer" }} />
                </OverlayTrigger>
            }
            <div className="transcript-question-response">
                {props.showExplanation && hasExplanation &&
                    <>
                        <OverlayTrigger trigger="click" rootClose placement="top" overlay={explanationPopover}>
                            <span ref={explanationShowcaseTarget} style={{ cursor: "pointer" }}><AnswerIconContainer answerResult={props.askedQuestion.getNormalizedQuestionAnswerPair().getAnswer()} includeText={false} /></span>
                        </OverlayTrigger>
                    </>
                }
                {!(props.showExplanation && hasExplanation) &&
                    <AnswerIconContainer answerResult={props.askedQuestion.getNormalizedQuestionAnswerPair().getAnswer()} includeText={false} />
                }
            </div>
        </td>
        <Overlay target={explanationShowcaseTarget.current} show={showExplanationShowcase} placement="top" rootClose rootCloseEvent="click" onHide={onExplanationShowcaseHide}>
            {(props) => (
                <Popover id="explanation-showcase-popover" {...props}>
                    <Popover.Body>
                        Click to view an explanation.
                    </Popover.Body>
                </Popover>
            )}
        </Overlay>
    </>;
}

export default QuestionTranscriptRow;