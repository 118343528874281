import { Accordion, Col, Row } from "react-bootstrap";
import GameStatsHeader from './GameStatsHeader';
import GameStatsBody from "./GameStatsBody";
import { TOP_LEVEL_COMPONENT_COL_LG, TOP_LEVEL_COMPONENT_COL_MD, TOP_LEVEL_COMPONENT_COL_XL, TOP_LEVEL_COMPONENT_COL_XXL } from "../../common/display-utils";

function GameStats() {
    return <>
        <Row className="justify-content-sm-center">
            <Col md={TOP_LEVEL_COMPONENT_COL_MD} lg={TOP_LEVEL_COMPONENT_COL_LG} xl={TOP_LEVEL_COMPONENT_COL_XL} xxl={TOP_LEVEL_COMPONENT_COL_XXL}>
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            <GameStatsHeader />
                        </Accordion.Header>
                        <Accordion.Body>
                            <GameStatsBody />
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Col>
        </Row>
    </>;
}



export default GameStats;