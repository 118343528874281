import './styles/index.scss';

import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import App from './App';
import reportWebVitals from './reportWebVitals';
import SessionPage from './SessionPage';
import ErrorScreen, { ErrorScreenType } from './components/shared/ErrorScreen';
import { getFirebaseApp } from './firebase/firebase-app';
import ProfilePage from './ProfilePage';
import GameResultsPage from './GameResultsPage';
import CreateGamePage from './CreateGamePage';
import BrowseGamesPage from './BrowseGamesPage';
import DailyGamesPage from './DailyGamesPage';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorScreen type={ErrorScreenType.NOT_FOUND} />,
  },
  {
    path: "session/:sessionId",
    element: <SessionPage />,
    errorElement: <ErrorScreen type={ErrorScreenType.NOT_FOUND} />,
  },
  {
    path: "game/:gameId",
    element: <App />,
    errorElement: <ErrorScreen type={ErrorScreenType.NOT_FOUND} />,
  },
  {
    path: "profile",
    element: <ProfilePage />,
    errorElement: <ErrorScreen type={ErrorScreenType.NOT_FOUND} />,
  },
  {
    path: "gameResult/:gameId",
    element: <GameResultsPage />,
    errorElement: <ErrorScreen type={ErrorScreenType.NOT_FOUND} />,
  },
  {
    path: "create",
    element: <CreateGamePage />,
    errorElement: <ErrorScreen type={ErrorScreenType.NOT_FOUND} />,
  },
  {
    path: "browse",
    element: <BrowseGamesPage />,
    errorElement: <ErrorScreen type={ErrorScreenType.NOT_FOUND} />,
  },
  {
    path: "dailyGames",
    element: <DailyGamesPage />,
    errorElement: <ErrorScreen type={ErrorScreenType.NOT_FOUND} />,
  }
]);

// Initialize Firebase + Analytics.
getFirebaseApp();

root.render(
  <RouterProvider router={router} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
