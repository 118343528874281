import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import { AnswerResult } from "../common/global-enums";
import { getFirebaseApp } from "./firebase-app";

/* Represents what's sent in the request to the Cloud function. */
interface FunctionRequest {
    gameSessionId: string;
    userId: string;
    questionKey: string;
    answerResult: AnswerResult;
    answerExplanation: string;
    markAsMisleading: boolean;
}

export function setMisleadingAnswer(request: FunctionRequest): Promise<boolean> {
    const functions = getFunctions(getFirebaseApp());
    // Connect to local functions when running locally.
    if (process.env.NODE_ENV === "development") {
        connectFunctionsEmulator(functions, "127.0.0.1", 5001);
    }
    const setMisleadingAnswerFunction = httpsCallable<FunctionRequest, void>(functions, 'setMisleadingAnswer');
    return setMisleadingAnswerFunction(request).then(() => {
        return true;
    }).catch((error) => {
        console.log("Error while setting misleading answer: " + error);
        return false;
    });
}