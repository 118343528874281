import React from 'react';
import Container from 'react-bootstrap/Container';
import { useState } from 'react';
import Header from './components/shared/Header';
import PageLoadingScreen from './components/PageLoadingScreen';
import { UserInfo, getUserInfo } from './common/user-info';
import Footer from './components/shared/Footer';
import CreateGameForm from './components/create/CreateGameForm';
import GlobalStateProvider from './context/GlobalStateContext';
import { GameState } from './common/global-enums';
import { Col, Row } from "react-bootstrap";
import { TOP_LEVEL_COMPONENT_COL_LG, TOP_LEVEL_COMPONENT_COL_MD, TOP_LEVEL_COMPONENT_COL_XL, TOP_LEVEL_COMPONENT_COL_XXL } from "./common/display-utils";

const CreateGamePage = (): React.ReactElement => {
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [userInfo, setUserInfo] = useState<UserInfo>();

    if (isPageLoading) {
        getUserInfo().then((userInfo: UserInfo) => {
            setUserInfo(userInfo);
            setIsPageLoading(false);
        });
    }
    return (
        <>
            {isPageLoading && <PageLoadingScreen />}
            {
                !isPageLoading &&
                <>
                    <GlobalStateProvider
                        acceptedVariants={[]}
                        acceptedThemeWords={[]}
                        faq={[]}
                        gameId={""}
                        globalGameStats={undefined}
                        secretWord={""}
                        theme={""}
                        dailyWordNumber={0}
                        playerUserId={""}
                        initialGameState={GameState.NOT_STARTED}
                        initialGameTranscriptRecords={[]}
                        initialHints={[]}
                        initialGameSessionId={""}
                        initialUserInfo={userInfo!}
                        initialCurrentWinStreak={0}
                    >
                        <Header />
                        <Container fluid="sm">
                            <div className="mt-4">
                                <Row className="justify-content-sm-center">
                                    <Col md={TOP_LEVEL_COMPONENT_COL_MD} lg={TOP_LEVEL_COMPONENT_COL_LG} xl={TOP_LEVEL_COMPONENT_COL_XL} xxl={TOP_LEVEL_COMPONENT_COL_XXL}>
                                        <CreateGameForm />
                                    </Col>
                                </Row>
                            </div>
                        </Container>
                        <Footer />
                    </GlobalStateProvider>
                </>
            }
        </>
    );
}

export default CreateGamePage;
