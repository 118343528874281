import { useContext } from 'react';
import { GlobalStateContext, IGlobalStateContextValue } from '../../context/GlobalStateContext';
import { capitalizeFirstLetter } from '../../common/word-utils';


// Displays the theme of the day.
function ThemeContainer() {
  const {
    theme,
    dailyWordNumber,
  } = useContext<IGlobalStateContextValue | undefined>(GlobalStateContext)!;

  const themePrefix = dailyWordNumber > 0 ? "Quest #" + dailyWordNumber : "Game";

  return (
    <div className="text-center">{themePrefix} Theme: <strong>{capitalizeFirstLetter(theme)}</strong></div>
  )
}

export default ThemeContainer;