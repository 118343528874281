import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import { getFirebaseApp } from "./firebase-app";
import { HintDto } from "../common/hint";

/* Represents what's sent in the request to the Cloud function. */
interface FunctionRequest {
    word: string;
    theme: string;
    hints: HintDto[];
    creatorUserId: string;
}

/* Represents what's returned in the response from the Cloud function. */
interface FunctionResponse {
    gameId: string;
}

export function createGame(request: FunctionRequest): Promise<string> {
    const functions = getFunctions(getFirebaseApp());
    // Connect to local functions when running locally.
    if (process.env.NODE_ENV === "development") {
        connectFunctionsEmulator(functions, "127.0.0.1", 5001);
    }
    const createGameFunction = httpsCallable<FunctionRequest, FunctionResponse>(functions, 'createGame');
    return createGameFunction(request).then((result) => {
        return result.data.gameId;
    }).catch((error) => {
        console.log("Error while creating game: " + error);
        throw error;
    });
}