import { Game } from "./game";
import { calculateScore } from "./score-utils";

export class UserStats {
    private currentWinStreak: number;
    private maxWinStreak: number;
    private dailyWordResultCountMap: Map<number, number>;
    private totalGamesPlayed: number;
    private gamesWon: number;
    private userCreatedGames: Game[];
    // Expressed out of 100, e.g. 95.5 to represent 95.5%.
    private winRate: number;
    private averageQuestionsToWin: number;
    private score: number;

    constructor(currentWinStreak: number, maxWinStreak: number, dailyWordResultCountMap: Map<number, number>, userCreatedGames: Game[]) {
        this.currentWinStreak = currentWinStreak;
        this.maxWinStreak = maxWinStreak;
        this.dailyWordResultCountMap = dailyWordResultCountMap;
        this.userCreatedGames = userCreatedGames;

        let wonGames = 0;
        let totalGames = 0;
        let totalQuestionsToWin = 0;
        for (const [gameResult, numGames] of dailyWordResultCountMap.entries()) {
            totalGames += numGames;
            if (gameResult > 0) {
                wonGames += numGames;
                totalQuestionsToWin += (gameResult * numGames);
            }
        }

        this.totalGamesPlayed = totalGames;
        this.gamesWon = wonGames;
        this.winRate = totalGames === 0 ? 0 : Number(((wonGames / totalGames) * 100).toFixed(2));
        this.averageQuestionsToWin = wonGames === 0 ? 0 : Number((totalQuestionsToWin / wonGames).toFixed(2));
        this.score = calculateScore(this.winRate, this.averageQuestionsToWin);
    }

    public getCurrentWinStreak(): number {
        return this.currentWinStreak;
    }

    public getMaxWinStreak(): number {
        return this.maxWinStreak;
    }

    public getDailyWordResultCountMap(): Map<number, number> {
        return this.dailyWordResultCountMap;
    }

    public getTotalGamesPlayed(): number {
        return this.totalGamesPlayed;
    }

    public getGamesWon(): number {
        return this.gamesWon;
    }

    public getWinRate(): number {
        return this.winRate;
    }

    public getAverageQuestionsToWin(): number {
        return this.averageQuestionsToWin;
    }

    public getScore(): number {
        return this.score;
    }

    public getUserCreatedGames(): Game[] {
        return this.userCreatedGames;
    }
}