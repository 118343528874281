import { connectFunctionsEmulator, getFunctions, httpsCallable } from "firebase/functions";
import { getFirebaseApp } from './firebase-app';
import { GameWithUserResult, GameWithUserResultDto } from "../common/game-with-user-result";
import { BrowseGamesResponse } from "../common/browse-games-response";

/* Represents what's sent in the request to the Cloud function. */
interface FunctionRequest {
    userId: string;
}

/* Represents what's returned in the response from the Cloud function. */
interface FunctionResponse {
    recentGames: GameWithUserResultDto[];
    popularGames: GameWithUserResultDto[];
}

/** 
 * Calls the Cloud function to browse games.
 */
export function browseGames(userId: string): Promise<BrowseGamesResponse> {
    const functions = getFunctions(getFirebaseApp());
    // Connect to local functions when running locally.
    if (process.env.NODE_ENV === "development") {
        connectFunctionsEmulator(functions, "localhost", 5001);
    }
    const browseGames = httpsCallable<FunctionRequest, FunctionResponse>(functions, 'browseGames');
    return browseGames({ userId }).then((result) => {
        return {
            recentGames: result.data.recentGames.map((gameWithUserResultDto) => GameWithUserResult.fromDto(gameWithUserResultDto)),
            popularGames: result.data.popularGames.map((gameWithUserResultDto) => GameWithUserResult.fromDto(gameWithUserResultDto))
        };
    }).catch((error) => {
        console.log("Error while browsing games from endpoint: " + error);
        throw error;
    });
}

